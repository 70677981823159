/* eslint-disable no-unused-vars */
/* eslint-disable id-blacklist */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import Table from "../../components/table/Table";
import Pagination from "../../components/pagination/Pagination";
import Prompt from "../../components/prompt/Prompt";
import ButtonDanger from "../../components/button/ButtonDanger";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import ButtonSecondary from "../../components/button/ButtonSecondary";
import EditRoleModal from "../../components/edit_role_modal/EditRoleModal";
import useUserContext from "../../hooks/useUserContext";
import { debounce, showPrompt, generateClassName } from "../../services/util";
import { validateName, validateEmail } from "../../services/validation";
import { MAGIC_NUMBERS, ADMINTYPE, LOCATIONS } from "../../constant";
import notify from "../../services/toast";
import getAdmins from "./getAdmins";
import deleteAdmin from "./deleteAdmin";
import createAdmin from "./createAdmin";
import updateAdmin from "./updateAdmin";
import resetAdminPassword from "./resetAdminPassword";

function AdministratorAdmins() {
  const { userState } = useUserContext();
  const [admins, setAdmins] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [role, setRole] = useState("");
  const [isAdminsLoading, setIsAdminsLoading] = useState(true);
  const [pagination, setPagination] = useState({ limit: 10, total: 0, page: 0, remaining: 0 });
  const [displayPromptMessage, setDisplayPromptMessage] = useState({ heading: "", body: "" });
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newRole, setNewRole] = useState([]);

  const [newFirstNameValid, setNewFirstNameValid] = useState(false);
  const [newLastNameValid, setNewLastNameValid] = useState(false);
  const [newEmailValid, setNewEmailValid] = useState(false);

  const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);
  const [isUpdatingAdmin, setIsUpdatingAdmin] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const roleRef = useRef(role);
  roleRef.current = role;
  const deletePromptRef = useRef(null);
  const resetPasswordPromptRef = useRef(null);
  const roleSelectRef = useRef(null);
  const locationSelectRef = useRef(null);

  useEffect(() => {
    const result = validateName(newFirstName);
    setNewFirstNameValid(result);
  }, [newFirstName]);

  useEffect(() => {
    const result = validateName(newLastName);
    setNewLastNameValid(result);
  }, [newLastName]);

  useEffect(() => {
    const result = validateEmail(newEmail);
    setNewEmailValid(result);
  }, [newEmail]);

  const fetchAdmins = async (accessToken, page, _keyword, _role) => {
    setIsAdminsLoading(true);
    const response = await getAdmins(
      {
        page,
        keyword: _keyword,
        role: _role,
        limit: pagination.limit,
      },
      accessToken
    );
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setAdmins(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
    setIsAdminsLoading(false);
  };

  const handleCreate = async event => {
    event.preventDefault();
    setIsCreatingAdmin(true);
    try {
      if (!newFirstName || !newLastName || !newEmail || !newLocation || !newRole.length) {
        notify("One or more fields are invalid. Check and try again", "error");
        return;
      }
      const payload = {
        firstName: newFirstName,
        lastName: newLastName,
        emailAddress: newEmail,
        location: newLocation,
        role: newRole.map(role => role.value),
      };
      const response = await createAdmin(payload, userState.accessToken);
      if (response.status === "success") {
        setNewFirstName("");
        setNewLastName("");
        setNewRole([]);
        setNewEmail("");
        setNewLocation("");
        roleSelectRef.current.clearValue();
        locationSelectRef.current.clearValue();
        fetchAdmins(userState.accessToken, 0, keyword, role);
        notify("Role created successfully", "info");
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setIsCreatingAdmin(false);
    }
  };

  const handleSearch = event => {
    const query = event.target.value;
    fetchAdmins(userState.accessToken, 0, query, roleRef.current);
    setKeyword(query);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchAdmins(userState.accessToken, pagination.page - 1, keyword, role);
  };

  const handleNextFetch = async () => {
    await fetchAdmins(userState.accessToken, pagination.page + 1, keyword, role);
  };

  const handleFilterBy = value => {
    fetchAdmins(userState.accessToken, 0, keyword, value);
    setRole(value);
  };

  const handleDeletePrompt = admin => {
    showPrompt("Delete Admin", `Are you sure you want to delete "${admin.firstName} ${admin.lastName}" admin? This action can't be undone.`, deletePromptRef, "open", setDisplayPromptMessage);
    setSelectedAdmin(admin);
  };
  const handleResetPasswordPrompt = admin => {
    showPrompt("Reset Admin Password", `Are you sure you want to reset the password for "${admin.firstName} ${admin.lastName}"? This action can't be undone.`, resetPasswordPromptRef, "open", setDisplayPromptMessage);
    setSelectedAdmin(admin);
  };

  const handleDeleteAdmin = async () => {
    const response = await deleteAdmin(selectedAdmin._id, userState.accessToken);
    if (response.status === "success") {
      notify("Admin successfully deleted", "info");
      deletePromptRef.current.closePrompt();
      fetchAdmins(userState.accessToken, 0, keyword, role);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
  };

  const handleResetPasswordAdmin = async () => {
    const body = { 
      emailAddress: selectedAdmin.emailAddress,
      firstName: selectedAdmin.firstName, 
      lastName: selectedAdmin.lastName
     };
    const response = await resetAdminPassword(body, userState.accessToken);
    if (response.status === "success") {
      notify("Admin password successfully reset", "info");
      resetPasswordPromptRef.current.closePrompt();
      fetchAdmins(userState.accessToken, 0, keyword, role);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
  };

  const handleOpenEditModal = admin => {
    setSelectedAdmin(admin);
    setShowEditModal(true);
  };

  const handleSave = async payload => {
    setIsUpdatingAdmin(true);
    try {
      const response = await updateAdmin(selectedAdmin._id, payload, userState.accessToken);
      if (response.status === "success") {
        fetchAdmins(userState.accessToken, pagination.page, keyword, role);
        setShowEditModal(false);
        notify("Role updated successfully", "info");
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setIsUpdatingAdmin(false);
    }
  };

  useEffect(() => {
    fetchAdmins(userState.accessToken, 0, "", "");
  }, []);

  console.log(userState);
  return (
    <>
      <div className="w-full flex flex-col sm:flex-row justify-between">
        <input
          type="text"
          name="search"
          onInput={debouncedHandleSearch}
          placeholder="Search admins"
          className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-full sm:max-w-md sm:text-base"
        />
        <Select
          className="border-0 mt-2 w-full sm:w-64 sm:mt-0"
          placeholder="Filter by..."
          isDisabled={false}
          isClearable={true}
          isLoading={false}
          isRtl={false}
          isSearchable={true}
          name="filter"
          options={Object.keys(ADMINTYPE).map(role => ({
            value: role,
            label: `${ADMINTYPE[role]}`,
          }))}
          onChange={event => handleFilterBy(event ? event.value : "")}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#ecf8ef",
              primary: "#56c271",
            },
          })}
        />
      </div>
      <h2 className="text-md text-gray-400 font-bold mt-5">Create a role</h2>
      <form className="mt-2 w-full md:w-4/5 space-y-3">
        <div className="w-full flex gap-5">
          <div className="w-full">
            <div className="mt-1">
              <input
                onChange={event => setNewFirstName(event.target.value)}
                type="text"
                name="first_name"
                id="first_name"
                value={newFirstName}
                autoComplete="off"
                className={generateClassName(!newFirstNameValid && newFirstName)}
                placeholder="First Name"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="mt-1">
              <input
                onChange={event => setNewLastName(event.target.value)}
                type="text"
                name="last_name"
                id="last_name"
                value={newLastName}
                autoComplete="off"
                className={generateClassName(!newLastNameValid && newLastName)}
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex gap-5">
          <div className="w-full">
            <div className="mt-1">
              <input
                onChange={event => setNewEmail(event.target.value)}
                type="email"
                name="email"
                id="email"
                value={newEmail}
                autoComplete="off"
                className={generateClassName(!newEmailValid && newEmail)}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="mt-1">
              <Select
                placeholder="Role"
                isDisabled={false}
                isClearable={true}
                isLoading={false}
                isRtl={false}
                isSearchable={false}
                isMulti={true}
                name="role"
                options={Object.keys(ADMINTYPE).map(role => ({
                  value: role,
                  label: ADMINTYPE[role],
                }))}
                onChange={event => setNewRole(event)}
                ref={roleSelectRef}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#ecf8ef",
                    primary: "#56c271",
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex gap-5">
          <div className="w-full">
            <Select
              placeholder="Location"
              isDisabled={false}
              isClearable={true}
              isLoading={false}
              isRtl={false}
              isSearchable={false}
              name="location"
              options={LOCATIONS}
              onChange={event => setNewLocation(event ? event.value : "")}
              ref={locationSelectRef}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ecf8ef",
                  primary: "#56c271",
                },
              })}
            />
          </div>
          <div className="w-full text-right">
            <div className="w-32 ml-auto">
              <ButtonPrimary
                text={isCreatingAdmin ? "Please wait..." : "Create"}
                size="md"
                action={handleCreate}
                loading={!newFirstNameValid || !newLastNameValid || !newEmailValid || !newRole.length || isCreatingAdmin || isUpdatingAdmin ? true : false}
              />
            </div>
          </div>
        </div>
      </form>
      <Table
        isLoading={isAdminsLoading}
        columns={[
          {
            name: "First Name",
            value: "firstName",
          },
          {
            name: "Last Name",
            value: "lastName",
          },
          {
            name: "Email address",
            value: "emailAddress",
          },
          {
            name: "Role",
            value: admin => <div>{admin.role.join(",")}</div>,
            type: "render",
          },
          {
            name: "Location",
            value: admin => {
              const selectedLocation = LOCATIONS.find(loc => loc.value === admin.location);
              return <div>{selectedLocation ? selectedLocation.label : "-"}</div>;
            },
            type: "render",
          },
          // {
          //   name: "Status",
          //   value: "status",
          // },
          {
            name: "Date",
            value: "createdAt",
          },
          {
            name: "Action",
            value: admin => (
              <div className="flex justify-between space-x-2">
                <ButtonDanger text="Delete" action={() => handleDeletePrompt(admin)} />
                <ButtonPrimary text="Update" action={() => handleOpenEditModal(admin)} />
                <ButtonSecondary text="Reset Password" action={() => handleResetPasswordPrompt(admin)} />
              </div>
            ),
            type: "render",
          },
        ]}
        data={admins}
      />
      <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={deletePromptRef} action={handleDeleteAdmin} />
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={resetPasswordPromptRef} action={handleResetPasswordAdmin} />
      {showEditModal && (
        <EditRoleModal
          open={showEditModal}
          admin={selectedAdmin}
          onClose={() => {
            setSelectedAdmin(null);
            setShowEditModal(false);
          }}
          onSave={handleSave}
        />
      )}
    </>
  );
}

export default AdministratorAdmins;