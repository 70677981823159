/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import useUserContext from "../../../hooks/useUserContext";
import { Outlet, useNavigate, NavLink } from "react-router-dom";
import { debounce } from "../../../services/util";
import {
	PLANS,
	COHATCH_LOCATIONS,
	MAGIC_NUMBERS,
	COHATCH_MARKETS,
	APP_STATUS,
} from "../../../constant";
import Select from "react-select";
import getApplications from "../../scholarship_applications/requests/getApplications";
import { Total } from "./AppLocationCard";
import getImpactMarketData from "./getImpactMarketData";
import getAllImpactStats from "../dashboard/getAllImpactStats";
import notify from "../../../services/toast";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function AllLocations() {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const type = queryParams.get("type") || "";

	const tabs = [
		{ name: "All", value: "", end: true },
		{ name: "Give", value: "give" },
		{ name: "Boost", value: "boost" },
		{ name: "Community Partner (coming soon)", value: "#" },
	];

	const markets = [
		"All",
		"Cincinnati",
		"Cleveland",
		"Columbus",
		"Indianapolis",
		"Tampa",
		"Pittsburgh",
		"Charlotte",
	];

	const { userState } = useUserContext();
	const [apps, setApps] = useState([]);
	const [marketData, setMarketData] = useState([]);
	const [market, setMarket] = useState("");
	const [isMarketDataLoading, setIsMarketDataLoading] = useState(true);
	const [isAppsLoading, setIsAppsLoading] = useState(true);
	const [isListingCategoryLoading, setIsListingCategoryLoading] =
		useState(false);
	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 11,
	});
	const [isStatsLoading, setIsStatsLoading] = useState(false);
	const [dashStats, setStats] = useState({});

	const applicationsByCategoryData = {
		labels: APP_STATUS.map((category) => category.label),
		datasets: [
			{
				label: "Listing By Market",
				data: APP_STATUS.map((category) => {
					const item = (marketData.applicationsByCategory || []).find(
						(mem) => mem._id === category.value
					);
					return item ? item.total : 0;
				}),
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};

	const handleSearch = () => {
		// const query = event.target.value;
		// fetchUsers(userState.accessToken, 0, query, productRef.current);
		// setKeyword(query);
		return 1;
	};

	const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

	const cities = COHATCH_LOCATIONS.cities;

	function renderLocationApps(app, index) {
		return <AppLocationCard key={index} application={app} />;
	}

	const handleNavigate = (url) => {
		navigate(`/dashboard/locations?type=${url}`);
	};

	const fetchMarketData = async () => {
		const response = await getImpactMarketData(userState.accessToken);
		if (response.status === "success") {
			setMarketData(response.data);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsMarketDataLoading(false);
		//setIsListingCategoryLoading(false);
	};

	const fetchStats = async () => {
		const response = await getAllImpactStats(userState.accessToken);
		if (response.status === "success") {
			setStats(response.data);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsStatsLoading(false);
	};

	const handleChangeMarket = (value) => {
		const city = value.value;
		navigate(`/dashboard/locations/${city}`);
	};

	useEffect(() => {
		fetchMarketData();
		fetchStats();
	}, [type]);

	console.log(dashStats, "market");
	return (
		<>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-20">
				<div className="mb-5 mt-7">
					<h1 className="mb-5 font-bold text-3xl">Scholarship Overview</h1>
					<p className="font-bold text-2xl">All Cities</p>
				</div>
				<div className="max-w-7xl">
					<div className="sm:hidden">
						<label htmlFor="tabs" className="sr-only">
							Select a tab
						</label>
						{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
						<select
							id="tabs"
							name="tabs"
							className="block w-full focus:ring-primary-400 focus:border-primary-400 border-primary-400 rounded-md"
							defaultValue={`/dashboard/locations${
								tabs.value ? `?type=${tabs.value}` : ""
							}`}
							onChange={(evt) => handleNavigate(evt.target.value)}
						>
							{tabs.map((tab) => (
								<option
									key={tab.name}
									value={tab.value}
									defaultValue={`/dashboard/locations${
										tab.value ? `?type=${tab.value}` : ""
									}`}
								>
									{tab.name}
								</option>
							))}
						</select>
					</div>
					<div className="hidden sm:block">
						<nav
							className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
							aria-label="Tabs"
						>
							{tabs.map((tab, tabIdx) => (
								<NavLink
									key={tab.name}
									to={`/dashboard/locations${
										tab.value ? `?type=${tab.value}` : ""
									}`}
									end={tab?.end}
									className={classNames(
										tab.value === type
											? "border-primary-400 text-primary-400"
											: "border-transparent text-success-800 hover:text-primary-400 hover:border-primary-400",
										tabIdx === 0 ? "rounded-l-lg" : "",
										tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
										"group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
									)}
									aria-hidden="true"
								>
									<span>{tab.name}</span>
								</NavLink>
							))}
						</nav>
					</div>
				</div>

				{/* Market Data Table*/}
				<Total marketData={marketData} type={type} dashstats={dashStats} />
			</div>

			<Outlet />
		</>
	);
}

export default AllLocations;
