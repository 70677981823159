/* eslint-disable */
import React, { useState, useEffect } from "react";
import { format, addYears } from "date-fns";
import SelectInput from "../../components/select_input/SelectInput";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import { v4 as uuid } from "uuid";
import { APP_LOCATIONS, COHATCH_MARKETS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import Confett from "./Confetti";
import { useNavigate } from "react-router-dom";
import ApplicationFile from "./ApplicationFile";
import Modal from "../../components/modal/Modal";
import DefaultState from "../../components/default_state/DefaultState";
import Logo from "../../assets/NonProfitmed.png";
import SULogo from "../../assets/StartUp.png";
import { inArray} from "../../services/util";


// import {
//   useLoadScript,
//   AutoComplete
// } from "@react-google-maps-api";
function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function EditApplicationModal({
	handleSubmit,
	open,
	onClose,
	app,
}) {
  console.log(app);
	const { userState } = useUserContext();
	const navigate = useNavigate();
	const showConfetti = React.useState(false);
	const [appApprovedModal, setAppApprovedModal] = useState(false)
  const [appDeniedModal, setAppDeniedModal] = useState(false)
  const [denyReasonModal, setDenyReasonModal] = useState(false);
  const [resetApp, setResetApp] = useState();
  const [market, setMarket] = useState();

  const [scoreMemAwarded, setScoreMemAwarded] = useState();

  const [awardedMember, setAwardedMember] = useState();
	const [tabs, setTabs] = useState([
		{ name: "Application" },
		{ name: "Scorecard" },
		{ name: "PDF" },
	]);
	const [amount, setAmount] = React.useState("")
	const [selectedTab, setSelectedTab] = useState("Application");
	const createdAt = app?.createdAt ? app.createdAt : null;
  const comarket = ""+app.market;
  const location = ""+app.coLocation;
  const Market = comarket.toUpperCase();
  const Colocation = location.toUpperCase();
 

  function addYears(date, years) {
		date.setFullYear(date.getFullYear() + years);
		return date;
	}

  var submissionDate = new Date(createdAt);
	var newDate = addYears(submissionDate, 1);

  {/* {mlStatus === "Approved" && cmStatus === "Approved" && (
												<Confett />
											)} */}
	// Updating Application
	const [payload, setPayload] = React.useState({
		firstName: app.firstName,
		lastName: app.lastName,
		email: app.email,
		phoneNumber: app.phoneNumber,
		cmStatus: app.cmStatus,
		mlStatus: app.mlStauts,
		appStatus: app.appStatus,
		officeRnd: app.officeRnd,
		scoreMission: app.scoreMission,
		scoreImpact: app.scoreImpact,
		scoreFit: app.scoreFit,
		scoreMemAwarded: app.scoreMemAwarded,
		annualAward: app.annualAward,
    denyReason: app.denyReason,
    notes: app.notes,
    awardedMember: app.awardedMember,
    market: app.market,
    coLocation: app.coLocation,
    status501: app.status501,
    dateFounded: app.dateFounded
	});

  //Locations
  const generateCohatchMarket = () => {
  const cohatchMarket = [];

  for (const market in COHATCH_MARKETS) {
    cohatchMarket.push(COHATCH_MARKETS[market]);
  }
  return cohatchMarket.flat();
};

const generateCohatchLocation = () => {
  if (market) {
    const cohatchLocation = [];
    cohatchLocation.push(APP_LOCATIONS[market]["locations"]);
    return cohatchLocation.flat();
  } else {
    return [];
  }
};

 const handleMarketChange = (event) => {
  if (event) {
    const { value } = event;
    setMarket(value);
    //handleChange("market", market);
    handleChange("coLocation", null);
    // setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
  } else {
   // setMarket(null);
   // handleChange("market", null);
    handleChange("coLocation", null);
  }
};

const handleLocationsChange = (event) => {
 
  if (event) {
    
    const { label } = event;

    handleChange("coLocation", label);
    
  } else {

    handleChange("coLocation", null);
   
    // setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
  }
};
//Locations End

	const statusOPTIONS = [
		{ value: "rejected", label: "Rejected" },
		{ value: "approved", label: "Approved" },
	];

  	const resetAppOption = [
		{ value: "Yes", label: "Yes, I want to set application Status to PENDING" },
	];

	const orgAmountOPTIONS = [
    { value: 0, label: "0" },
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" }
	];

  const scorecardOPTIONS = [
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
	];

  const denyReasons = [
    {value: "At Capacity", label: "Location is at capacity"},
    {value: "Previous Member", label: "Applicant is a previous COhatch member"},
    {value: "Current Member", label: "Applicant is a current COhatch member"},
    {value: "Poor Fit", label: "Applicant was a Poor Fit"}
  ]
	
  //Add Notes
  const [userNotes, setUserNotes] = useState(payload.notes || []);
  const [notes, setNotes] = React.useState([]);

  const [newNotes, setNewNotes] = useState(null);
  const handleAddNote = (event) => {
    console.log("inside notes");
    event.preventDefault();
   if (!newNotes) {
    return;
   }
    if (inArray(userNotes, newNotes)) {
      notify(`${newCause} already exists in your causes list.`, "error");
      return;
    }
   const addNote = newNotes.target.value;
   const prevNotes = app.notes
   setUserNotes(() => {
    return [...prevNotes, `${addNote} - ${userState.firstName} ${userState.lastName} ${new Date().toLocaleDateString("en-US")}`]
   });
   setPayload({
			...payload, 
			notes: userNotes,
		});
    //setNewNotes(null);
  }

// PAYLOAD 
  const handleChange = (key, value) => {
		setPayload({
			...payload,
			[key]: value,
		});
   
	};
	const handleUpdate = (payload) => {
		if ( payload.mlStatus === "approved") {
    setPayload({
        ...payload,
        appStatus: "approved",
      });
			setAppApprovedModal(true);
		} else if ( payload.cmStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} 
    else if ( payload.mlStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else {
			setPayload({
				...payload,
				appStatus: "pending",
			});
			handleSubmit(payload);
		}
	}
  const handleResendEmails = (payload) => {
		if ( app.mlStatus === "approved") {
    setPayload({
        ...payload,
        appStatus: "approved",
      });
			setAppApprovedModal(true);
		} else if ( app.cmStatus === "rejected" ) {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} 
    else if ( app.cmStatus === "approved" && app.mlStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else {
			setPayload({
				...payload,
				appStatus: "pending",
			});
			handleSubmit(payload);
		}
	}
	const handleApproveUpdate = () => {
		handleSubmit(payload);
		setAppApprovedModal(false);
    //add confetti
	}
  const handleDenyUpdate = () => {
  setAppDeniedModal(false);
  setDenyReasonModal(true);
  //add confetti
	}
  const handleDenyPayload = () => {
    handleSubmit(payload);
    setDenyReasonModal(false);
  }
	const handleGoBack = () => {
		setAppApprovedModal(false);
    setAppDeniedModal(false);
	}
	const handleAnnualAward = (evt) => {

	  const amount = evt.value * 2388;
    const members = evt.value;
		setAmount(amount);
    setScoreMemAwarded(members);
    setAwardedMember(members);
		handleChange("annualAward", amount);
	}

  const handleCharlotteAnnualAward = (evt) => {
	  const amount = evt.value * 1308;
    const members = evt.value;
		setAmount(amount);
    setScoreMemAwarded(members);
    setAwardedMember(members);
		handleChange("annualAward", amount);
	}
// END PAYLOAD
	const handleScoreSelections = (evt) => {
	}

	function handleClick() {
		navigate(`/impact/all_scholarships/file/${app.id}`);
	}

  React.useEffect(() => {
  setPayload({
			...payload,
			market: market,
		});
}, [market]);
React.useEffect(() => {
  setPayload({
			...payload,
			scoreMemAwarded: scoreMemAwarded,
		});
}, [scoreMemAwarded]);
React.useEffect(() => {
  setPayload({
			...payload,
			awardedMember: awardedMember,
		});
}, [awardedMember]);


React.useEffect(() => {
  setPayload({
			...payload,
			scoreMemAwarded: scoreMemAwarded,
		});
}, [scoreMemAwarded]);
React.useEffect(() => {
  setPayload({
			...payload,
			awardedMember: awardedMember,
		});
}, [awardedMember]);



	return (
		<>
			{/* {appApprovedModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center">
									<h1>
										Are you sure you would like to approve this application? Approving this application will notify the  applicant that they have been rewarded a scholarship.
									</h1>
									<ButtonPrimary size="md" text="Approve" action={handleApproveUpdate} />
									<button className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500" size="md" onClick={handleGoBack}>Go Back</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)} */}
      {/* {appDeniedModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center ">
									<h1 className="m-10">
										Are you sure you would like to reject this application? Rejecting this application will send an email to the applicant informing them that they have not been chosen to receive a scholarship. 
									</h1>
									<button className="w-full cursor-pointer relative rounded shadow-sm px-2.5 py-2 text-white bg-red-700 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
                  size="md" onClick={handleDenyUpdate} >Reject</button>
									<button className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500" size="md" onClick={handleGoBack}>Go Back</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)} */}
      {/* {denyReasonModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center ">
									<h1 className="m-10">
										Please select the reason you are rejecting this application.
									</h1>
                  	<div className=" w-full">
                      <div className="w-full mb-5">
                    <SelectInput
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md capitalize"
                    placeholder="Select Reason"
                    options={denyReasons}
                    setSelectedValue={(evt) => handleChange("denyReason", evt.value)}
                    
                  />
                 </div>
                 {payload.denyReason && (
                  <div className="w-full">
									<button className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
                  size="md" onClick={handleDenyPayload} >Submit</button>
									</div>
                 )}
                  </div>
                  
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)} */}
      <div className="mt-10">

     
			<div className="modal-dialog modal-dialog-scrollable">
				<div className={`${open ? "block" : "hidden"} relative z-10 `}>
					<div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
								<div className="flex cursor-pointer px-3">
									<button onClick={onClose}>
										<span className="material-icons-outlined text-4xl">
											chevron_left
										</span>
									</button>
								</div>

								


										<div className=" w-full sm:p-4 space-y-5">
											<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
												<div className="w-full">
													<label
														htmlFor="name"
														className=" block text-sm font-medium text-gray-700"
													>
														First Name
													</label>
													<div className="mt-1">
														<input
															className="capitalize block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-gray-500 focus:outline-none focus:ring-primary-400 focus:border-primary-400"
															type="text"
															defaultValue={app.firstName}
															onChange={(evt) => { handleChange("firstName", evt.target.value) }}
														/>
													</div>
												</div>

												<div className="w-full">
													<label
														htmlFor="name"
														className="block text-sm font-medium text-gray-700"
													>
														Last Name
													</label>
													<div className="mt-1">
														<input
															className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-gray-500 focus:outline-none focus:ring-primary-400 focus:border-primary-400"
															type="text"
															name="name"
															id="name"
															onChange={(evt) => { handleChange("lastName", evt.target.value) }}
															defaultValue={app.lastName}
														/>
													</div>
												</div>
											</div>
											<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
												<div className="w-full">
													<label
														htmlFor="jobTitle"
														className="block text-sm font-medium text-gray-700"
													>
														E-mail
													</label>
													<div className="mt-1">
														<input
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
															type="text"
															name="jobTitle"
															id="jobTitle"
															onChange={(evt) => { handleChange("email", evt.target.value) }}

															defaultValue={app.email}
														/>
													</div>
												</div>

												<div className="w-full">
													<label
														htmlFor="phone"
														className="block text-sm font-medium text-gray-700"
													>
														Phone Number
													</label>
													<div className="mt-1">
														<input
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
															type="tel"
															name="phone"
															id="phone"
															onChange={(evt) => { handleChange("phoneNumber", evt.target.value) }}
															defaultValue={app.phoneNumber}
														/>
													</div>
												</div>
											</div>

											<div className="w-full">
												<label
													htmlFor="location"
													className="block text-sm font-medium text-gray-700"
												>
													Location
												</label>
												
												<div className="mt-1">
                          <div className="w-full mt-3 flex gap-3 mb-6">

                          <div className="w-full">
                            <SelectInput
                              label="COhatch City:"
                              name="market"
                              id="market"
                              options={generateCohatchMarket()}
                              noOptionsMessage="No cities found"
                              placeholder={Market}
                              setSelectedValue={handleMarketChange}
                            />
                          </div>

                           <div className="w-full flex  gap-3">
                            <div className="w-full ">
                              <SelectInput
                              label="COhatch Location"
                              id="coLocation"
                              name="coLocation"
                              options={generateCohatchLocation()}
                             setSelectedValue={handleLocationsChange}
                             placeholder={Colocation}
                             
                            />
                          </div>
                          </div>

                          </div>
														
											</div>
                    </div>
											<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
												<div className="w-full">
													<label
														htmlFor="date"
														className="block text-sm font-medium text-gray-700"
													>
														Application Submission Date
													</label>
													<div className="mt-1">
														<time dateTime="" className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
														{format(new Date(createdAt), "MMMM do, yyyy")}
        												</time>
													</div>
												</div>

												<div className="w-full">
											
											<label
												htmlFor="linkedin"
												className="block text-sm font-medium text-gray-700"
											>
												Renewal Date
											</label>
											<div className="mt-1">
												<time dateTime="" className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">{app.reapplicant === "no" ?  `${format(new Date(newDate), "MMMM do, yyyy")}` : `${format(new Date("2025-01-01T20:35:47.158Z"), "MMMM do, yyyy")}`}
                        
                        </time>
											</div>
										</div>
											</div>
          <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
                        {app.type === "boost" && (
                          <div className="w-full">
                          <label
														htmlFor="mlStatus"
														className="block text-sm font-medium text-gray-700"
													>
													Date Founded
													</label>
                          <div className="mt-1">
                            	<input
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
															type="tel"
															name="phone"
															id="phone"
															onChange={(evt) => { handleChange("dateFounded", evt.target.value) }}
															defaultValue={app.dateFounded}
														/>
													
													</div>
												</div>
                        )}
                        <div className="w-full">
                       <button
                        type="button"
                        	onClick={() => {
														handleResendEmails(payload);
														onClose();
													}}
                        className="mt-6 inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          Resend Application Status Email to Scholar 
                          </button>
                          </div>
                      </div>
											<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">

												{userState.role[0] === "COMMUNITY_MANAGER" || userState.role[0] === "MASTER_APPROVAL" ? (

                          <div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-sm font-medium text-gray-700"
													>
														Community Manager Approval Status
													</label>
													<div className="mt-1">
                            {app.cmStatus === "pending" ? 
                            	<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md capitalize"
															placeholder={String(app.cmStatus).toUpperCase()}
															options={statusOPTIONS}
															setSelectedValue={(evt) => handleChange("cmStatus", evt.value)}
                              
														/> :
                            	<div className="mt-1">
														<div  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
														{String(app.cmStatus).toUpperCase()}
        												</div>
													</div>}
													
													</div>
												</div>
                        ) :
                        <div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-sm font-medium text-gray-700"
													>
														Community Manager Approval Status
													</label>
													<div className="mt-1">
                            
														<div  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
														{String(app.cmStatus).toUpperCase()}
        												</div>
													</div>
												</div>}

                        {userState.role[0] === "MARKET_LEADER" || userState.role[1] === "MARKET_LEADER" || userState.role[0] === "MASTER_APPROVAL" ? (
                          <div className="w-full">
                            <label
														htmlFor="linkedin"
														className="block text-sm font-medium text-gray-700"
													>
														Market Leader Approval Status
													</label>
													<div className="mt-1">
                            {app.mlStatus === "pending" && (app.cmStatus != "pending")? 
                            <SelectInput
															className="capitalize appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={statusOPTIONS}
															setSelectedValue={(evt) => handleChange("mlStatus", evt.value)}
															placeholder={String(app.mlStatus).toUpperCase()}
														/> : 
                           	<div className="mt-1">
														<div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
														{String(app.mlStatus).toUpperCase()}
        												</div>
													</div>}
														
													</div>
                          </div>
                        ) : 
                        <div className="w-full">
                          <label
														htmlFor="mlStatus"
														className="block text-sm font-medium text-gray-700"
													>
														Market Leader Approval Status
													</label>
                          <div className="mt-1">
                            
														<div  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
														{String(app.mlStatus).toUpperCase()}
        												</div>
													</div>
												</div>}
											</div>
											<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
												{app.type === "give" && (
                          <div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-sm font-medium text-gray-700"
													>
														501C3 Approved
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={statusOPTIONS}
															placeholder={String(app.status501).toUpperCase()}
															//defaultValue={app.admin.cmStatus}
															// placeholder="Select an option"
															//setSelectedValue={(event) => setCMStatus(event.value)}
															setSelectedValue={(evt) => { handleChange("status501", evt.value) }}
														/>
													</div>
												</div>
                        )}

												<div className="w-full">
													<label
														htmlFor="linkedin"
														className="block text-sm font-medium text-gray-700"
													>
														OfficeRnD Status
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={statusOPTIONS}
															// placeholder="Select an option"
															setSelectedValue={(evt) => { handleChange("officeRnd", evt.value) }}
															//value={app.admin.mlStatus}
															placeholder={String(app.officeRnd).toUpperCase()}
														/>
													</div>
												</div>
											</div>


                      	<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
                          
											<div className="w-full">

												<label
													htmlFor="bio"
													className="block text-sm font-medium text-gray-700"
												>
													Notes
												</label>
                            <div>
                              <textarea 
                              className="text-left whitespace-nowrap text-sm  appearance-none block px-3 py-2 w-full border border-gray-600 rounded-md "
                              type="text" 
                              rows="1"
                              placeholder="add new note..."
                              onChange={(evt) => { setNewNotes(evt) }}/>
                          </div>
                        </div>

                        <div className="">
                        <button 
                        className= "mt-5 cursor-pointer rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" type="submit" 
                        icon="add" 
                        size="md" 
                        onClick={handleAddNote}>+
                    </button>
                    </div>
											</div>
										</div>
                    

                          <div className="mt-1">
                            <div className="w-full flex items-center gap-3">
                              <div className="w-full">
                                <div className="mt-1">
                                	<div
                                   className="text-left whitespace-nowrap text-sm  appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md"
                                  >
                                    {/* show app.notes as default. But if payload .notes are present, replace with payload. notes. */}

                                    { payload.notes ?  payload.notes?.map((notes, index) => (
                                        <div key={uuid()}>
                                          <div key={index}>
                                            {notes}
                                          </div>
                                        </div>
                                      ))
                                    :    
                                    app.notes?.map((notes, index) => (
                                        <div key={uuid()}>
                                          <div key={index}>
                                            {notes}
                                          </div>
                                        </div>
                                      ))
                                      }
                                </div>
                              </div>
                            </div>
                            <div>
                      
                            </div>
                          </div>
											</div> 
                        
										<div className="w-full flex justify-end">
											<div className="mt-3">
												<button
													className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
													type="button"
													onClick={() => {
														handleUpdate(payload);
														onClose();
													}}
												>
													Save Changes
												</button>
											</div>
										</div>
						
        

                	{/* {selectedTab === "PDF" && (
									<ApplicationFile
										application={app} />
								)}

								{app.type === "give" && app.market != "charlotte" && selectedTab === "Scorecard" && (
                  <>
										<div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="mt-2 w-full">
												<label
													htmlFor="orgMission"
													className="block text-md font-medium text-gray-700 mb-4"
												>
													Description of Your Organization and Mission:
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgMission}
													/>
												</div>
											</div>


											<div className=" w-full">
												<label
													htmlFor="facebook"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													Clear Mission (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														placeholder={app.scoreMission}
														defaultValue={app.scoreMission}
														setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="orgAlign"
													className="block text-md font-medium text-black-700"
												>
													What aspect of COhatch's mission most aligns with this organization's mission?:
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgAlign}
													/>
												</div>
											</div>


											<div className="w-full">

												<label
													htmlFor="linkedin"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													COhatch Fit (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														value={app.scoreFit}
														placeholder={app.scoreFit}
														setSelectedValue={(evt) => handleChange("scoreFit", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
											<div className="w-full ">
												<label
													htmlFor="orgMission"
													className="text-md font-medium text-black-700"
												>
													How does your organization impact the local community?
												</label>
												<div className="">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgImpact}
													/>
												</div>
											</div>

											<div className="w-full">
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-md font-medium text-gray-700 mb-4 mt-4"
													>
														Community Impact (1-3 Scale)
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={scorecardOPTIONS}
															placeholder={app.scoreImpact}
															value={app.scoreImpact}
															setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="facebook"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Applying for Scholarship
												</label>
												<div className="mt-1">
													<input
														type="number"
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														placeholder={app.orgAmount}
													/>
												</div>
											</div>
											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Awarded Scholarship (5 max)
												</label>
													<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														setSelectedValue={handleAnnualAward}
														options={orgAmountOPTIONS}


														placeholder={app.awardedMember ? app.awardedMember : app.scoreMemAwarded}


													/>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="score"
													className="block text-sm font-medium text-gray-700"
												>
													Total Score (9 maximum)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														name="score"
														id="score"
														placeholder={app.scoreFit + app.scoreMission + app.scoreImpact}
													//  value={payload.scoreFit + payload.scoreMission + app.scoreImpact}
													/>
												</div>
											</div>

											<div className="w-full">

												<label
													htmlFor="awardAmount"
													className="block text-sm font-medium text-gray-700"
												>
													Annual Award ($199 x member for 12 months)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														type="awardAmount"
														name="awardAmount"
														id="awardAmount"
														value={amount}
                            placeholder={app.annualAward}
													/>
												</div>
											</div>

										</div>
										<div className="mt-10 w-full flex justify-end">
											<div>
												<button
													className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
													type="button"
													onClick={() => {
														handleSubmit(payload);
														onClose();
													}}
												>
													Save Changes
												</button>
											</div>
										</div>
									</>
                )}
                {app.type === "boost" && app.market != "charlotte" && selectedTab === "Scorecard" && (
                   <>
										<div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="mt-2 w-full">
												<label
													htmlFor="orgMission"
													className="block text-md font-medium text-gray-700 mb-4"
												>
													Description of Your Organization and Mission:
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400"
														defaultValue={app.orgMission}
													/>
												</div>
											</div>


											<div className=" w-full">
												<label
													htmlFor="facebook"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													Clear Mission (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														placeholder={app.scoreMission}
														defaultValue={app.scoreMission}
														setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}

													/>
												</div>
											</div>


										</div>

										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="orgAlign"
													className="block text-md font-medium text-black-700"
												>
													How will using COhatch's locations and resources help you reach your goals?
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgUses}
													/>
												</div>
											</div>


											<div className="w-full">

												<label
													htmlFor="linkedin"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													COhatch Fit (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														value={app.scoreFit}
														placeholder={app.scoreFit}
														setSelectedValue={(evt) => handleChange("scoreFit", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
											<div className="w-full ">
												<label
													htmlFor="orgMission"
													className="text-md font-medium text-black-700"
												>
													What social & economic impact does your company have on the local community?
												</label>
												<div className="">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgAlign}
													/>
												</div>
											</div>

											<div className="w-full">
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-md font-medium text-gray-700 mb-4 mt-4"
													>
														Community Impact (1-3 Scale)
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={scorecardOPTIONS}
															placeholder={app.scoreImpact}
															value={app.scoreImpact}
															setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="facebook"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Applying for Scholarship
												</label>
												<div className="mt-1">
													<input
														type="number"
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														placeholder={app.orgAmount}
													/>
												</div>
											</div>
											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Awarded Scholarship (5 max)
												</label>
													<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														setSelectedValue={handleAnnualAward}
														options={orgAmountOPTIONS}


														placeholder={app.awardedMember ? app.awardedMember : app.scoreMemAwarded}

													/>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="score"
													className="block text-sm font-medium text-gray-700"
												>
													Total Score (9 maximum)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														name="score"
														id="score"
														placeholder={app.scoreFit + app.scoreMission + app.scoreImpact}
													//  value={payload.scoreFit + payload.scoreMission + app.scoreImpact}
													/>
												</div>
											</div>

											<div className="w-full">

												<label
													htmlFor="awardAmount"
													className="block text-sm font-medium text-gray-700"
												>
													Annual Award ($199 x member for 12 months)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														type="awardAmount"
														name="awardAmount"
														id="awardAmount"
														value={amount}
                            placeholder={app.annualAward}
													/>
												</div>
											</div>

										</div>
										<div className="mt-10 w-full flex justify-end">
											<div>
												<button
													className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
													type="button"
													onClick={() => {
														handleSubmit(payload);
														onClose();
													}}
												>
													Save Changes
												</button>
											</div>
										</div>
									</>
                )}

                	{app.type === "give" && app.market === "charlotte" && selectedTab === "Scorecard" && (
                  <>
										<div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="mt-2 w-full">
												<label
													htmlFor="orgMission"
													className="block text-md font-medium text-gray-700 mb-4"
												>
													Description of mission and organization?
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgMission}
													/>
												</div>
											</div>


											<div className=" w-full">
												<label
													htmlFor="facebook"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													Clear Mission (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														placeholder={app.scoreMission}
														defaultValue={app.scoreMission}
														setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}

													/>
												</div>
											</div>


										</div>

										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="orgAlign"
													className="block text-md font-medium text-black-700"
												>
													What aspect of COhatch's mission most aligns with this organization's mission?:
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgAlign}
													/>
												</div>
											</div>


											<div className="w-full">

												<label
													htmlFor="linkedin"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													COhatch Fit (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														value={app.scoreFit}
														placeholder={app.scoreFit}
														setSelectedValue={(evt) => handleChange("scoreFit", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
											<div className="w-full ">
												<label
													htmlFor="orgMission"
													className="text-md font-medium text-black-700"
												>
													How does your organization impact the local community?
												</label>
												<div className="">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgImpact}
													/>
												</div>
											</div>

											<div className="w-full">
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-md font-medium text-gray-700 mb-4 mt-4"
													>
														Community Impact (1-3 Scale)
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={scorecardOPTIONS}
															placeholder={app.scoreImpact}
															value={app.scoreImpact}
															setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="facebook"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Applying for Scholarship
												</label>
												<div className="mt-1">
													<input
														type="number"
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														placeholder={app.orgAmount}
													/>
												</div>
											</div>
											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Awarded Scholarship (5 max)
												</label>
													<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														setSelectedValue={handleCharlotteAnnualAward}
														options={orgAmountOPTIONS}


														placeholder={app.awardedMember ? app.awardedMember : app.scoreMemAwarded}


													/>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="score"
													className="block text-sm font-medium text-gray-700"
												>
													Total Score (9 maximum)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														name="score"
														id="score"
														placeholder={app.scoreFit + app.scoreMission + app.scoreImpact}
													//  value={payload.scoreFit + payload.scoreMission + app.scoreImpact}
													/>
												</div>
											</div>

											<div className="w-full">

												<label
													htmlFor="awardAmount"
													className="block text-sm font-medium text-gray-700"
												>
													Annual Award ($199 x member for 12 months)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														type="awardAmount"
														name="awardAmount"
														id="awardAmount"
														value={amount}
                            placeholder={app.annualAward}
													/>
												</div>
											</div>

										</div>
										<div className="mt-10 w-full flex justify-end">
											<div>
												<button
													className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
													type="button"
													onClick={() => {
														handleSubmit(payload);
														onClose();
													}}
												>
													Save Changes
												</button>
											</div>
										</div>
									</>
                )}
                {app.type === "boost" && app.market === "charlotte" && selectedTab === "Scorecard" && (
                   <>
										<div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="mt-2 w-full">
												<label
													htmlFor="orgMission"
													className="block text-md font-medium text-gray-700 mb-4"
												>
													Description of mission and organization?
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgMission}
													/>
												</div>
											</div>


											<div className=" w-full">
												<label
													htmlFor="facebook"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													Clear Mission (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														placeholder={app.scoreMission}
														defaultValue={app.scoreMission}
														setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}

													/>
												</div>
											</div>


										</div>

										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="orgAlign"
													className="block text-md font-medium text-black-700"
												>
													How will using COhatch's locations and resources help you reach your goals?
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgUses}
													/>
												</div>
											</div>


											<div className="w-full">

												<label
													htmlFor="linkedin"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													COhatch Fit (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														value={app.scoreFit}
														placeholder={app.scoreFit}
														setSelectedValue={(evt) => handleChange("scoreFit", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
											<div className="w-full ">
												<label
													htmlFor="orgMission"
													className="text-md font-medium text-black-700"
												>
													What social & economic impact does your company have on the local community?
												</label>
												<div className="">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={app.orgAlign}
													/>
												</div>
											</div>

											<div className="w-full">
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-md font-medium text-gray-700 mb-4 mt-4"
													>
														Community Impact (1-3 Scale)
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={scorecardOPTIONS}
															placeholder={app.scoreImpact}
															value={app.scoreImpact}
															setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="facebook"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Applying for Scholarship
												</label>
												<div className="mt-1">
													<input
														type="number"
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														placeholder={app.orgAmount}
													/>
												</div>
											</div>
											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Awarded Scholarship (5 max)
												</label>
													<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														setSelectedValue={handleCharlotteAnnualAward}
														options={orgAmountOPTIONS}


														placeholder={app.awardedMember ? app.awardedMember : app.scoreMemAwarded}

													/>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="score"
													className="block text-sm font-medium text-gray-700"
												>
													Total Score (9 maximum)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														name="score"
														id="score"
														placeholder={app.scoreFit + app.scoreMission + app.scoreImpact}
													//  value={payload.scoreFit + payload.scoreMission + app.scoreImpact}
													/>
												</div>
											</div>

											<div className="w-full">

												<label
													htmlFor="awardAmount"
													className="block text-sm font-medium text-gray-700"
												>
													Annual Award ($199 x member for 12 months)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														type="awardAmount"
														name="awardAmount"
														id="awardAmount"
														value={amount}
                            placeholder={app.annualAward}
													/>
												</div>
											</div>

										</div>
										<div className="mt-10 w-full flex justify-end">
											<div>
												<button
													className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
													type="button"
													onClick={() => {
														handleSubmit(payload);
														onClose();
													}}
												>
													Save Changes
												</button>
											</div>
										</div>
									</>
                )} */}
							</div>
						</div>
					</div>
				</div>
			</div>
       </div>
		</>
	);
}

export default EditApplicationModal;


