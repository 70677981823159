/*eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import BarChart from "../charts/BarChart";
import { greetings } from "../../../services/util";
import useUserContext from "../../../hooks/useUserContext";
import Stats from "../../../components/philanthropy/Stats";
import LineGraph from "../charts/LineGraph";
import getApplications from "../../scholarship_applications/requests/getApplications";
import notify from "../../../services/toast";
import LeaderboardCard from "../charts/LeaderboardCard";
import DoughnutChart from "../charts/Doughnut";
import Spinner from "../../../components/spinner/Spinner";
import StoryCard from "../../../components/philanthropy/StoryCard";
import { Link } from "react-router-dom";
import getAllImpactStats from "./getAllImpactStats";
import { MAGIC_NUMBERS } from "../../../constant";
import ScoreCardInfo from "../charts/ScoreCardInfo";
import { Box, useTheme } from "@mui/material";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridToolbarExport,
} from "@mui/x-data-grid";
import { MdLocationPin } from "react-icons/md";
import { format } from "date-fns";
import deleteApplication from "../../scholarship_applications/requests/deleteApplication";
import Prompt from "../../../components/prompt/Prompt";
import { showPrompt } from "../../../services/util";
import SULogo from "../../../assets/StartUp.png";
import Logo from "../../../components/logo/Logo";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function ImpactHome() {
	const { userState } = useUserContext();
	const [isStatsLoading, setIsStatsLoading] = useState(false);
	const [featuredStory, setFeaturedStories] = React.useState([]);
	const [fetchingStory, setFetchingStories] = React.useState(false);
	const [dashstats, setStats] = useState({});

	const totalGiveApplications = dashstats.totalGiveApplications;
	const totalBoostApplications = dashstats.totalBoostApplications;
	const totalApprovedApplications = dashstats.totalApprovedApplications;

	const award = dashstats.annualAward;

	const stats = [
		{
			name: "Total Scholarships ($)",
			stat: (
				<span>
					${award?.[0]?.annualAward ? award[0].annualAward.toLocaleString() : 0}
				</span>
			),
			previousStat: "$0",
			change: "0%",
			changeType: "increase",
		},
		{
			name: "Total # of Approved Scholars",
			stat: totalApprovedApplications,
			previousStat: "0",
			change: "0%",
			changeType: "increase",
		},
		{
			name: "Total # of Give Applications",
			stat: totalGiveApplications,
			previousStat: "0",
			change: "10%",
			changeType: "increase",
		},
		{
			name: "Total # of Boost Applications",
			stat: totalBoostApplications,
			previousStat: "0",
			change: "0%",
			changeType: "increase",
		},
	];

	// const fetchFeaturedStories = async (accessToken) => {
	//   try {
	//   setFetchingStories(true);
	//   const response = await getFeaturedStories(accessToken);

	//   if (response.status === "success") {

	//     setFeaturedStories(response.data.results);
	//   }
	// } catch (error) {

	//   notify("Something went wrong. Please try again later", "error");

	// } finally {
	//   setFetchingStories(false);
	// }
	// };

	const fetchStats = async () => {
		const response = await getAllImpactStats(userState.accessToken);
		if (response.status === "success") {
			setStats(response.data, "stats");
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsStatsLoading(false);
	};

	//Leaderboard Info
	function renderApps(app, index) {
		return <LeaderboardCard key={index} application={app} />;
	}

	//Scorecard Info
	function renderScorecard(app, index) {
		return <ScoreCardInfo key={index} application={app} />;
	}

	function renderStory(story, index) {
		return <StoryCard key={index} story={story} />;
	}

	const [apps, setApps] = useState([]);
	const newApps = apps?.slice(0, 10);
	const [tableApps, setTableApps] = useState(newApps);

	function filterPendingApps(apps) {
		const pendingApps = apps.filter((apps) => apps.appStatus === "pending");

		return pendingApps;
	}

	const filteredPendingApps = filterPendingApps(apps);

	const pendingApps = filteredPendingApps.slice(0, 10);

	const [appTabs, setTabs] = useState([
		{ name: "New Applications" },
		{ name: "Pending Applications" },
	]);

	const [activeTab, setActiveTab] = useState("New Applications");

	const changeTab = (tab) => {
		if (tab === "New Applications") {
			setTableApps(newApps);
			setActiveTab(tab);
		}
		if (tab === "Pending Applications") {
			setTableApps(pendingApps);
			setActiveTab(tab);
		}
	};

	const handleDeletePrompt = (orgName, id) => {
		showPrompt(
			"Delete Application",
			`Are you sure you want to delete "${orgName}'s" application? This action cannot be undone.`,
			deletePromptRef,
			"open",
			setDisplayPromptMessage
		);
		setCurrentId(id);
	};

	const handleDelete = async () => {
		const response = await deleteApplication(currentId, userState.accessToken);
		if (response.status === "success") {
			notify("Application successfully deleted", "info");
			deletePromptRef.current.closePrompt();
			window.location.reload(true);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
	};

	const columns = [
		{
			field: "orgName",
			headerName: "Organization",
			flex: 1,
			renderCell: ({
				row: { type, logoImage, orgName, firstName, lastName, _id },
			}) => {
				return (
					<a href={`/dashboard/profile/${_id}`} target="_blank" rel="noopener noreferrer">
						<div className="float-left">
							<div className="text-center ">
								{logoImage?.small?.url ? (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={logoImage.small.url}
									/>
								) : logoImage?.logoImage?.url ? (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={logoImage.logoImage.url}
									/>
								) : (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={type === "give" ? Logo : SULogo}
									/>
								)}
							</div>
						</div>
						<div className="float-left ml-3 font-bold">
							<h1>{orgName}</h1>
							<p className="text-gray-500">
								{firstName} {lastName}
							</p>
						</div>
					</a>
				);
			},
		},
		{
			field: "type",
			headerName: "Scholarship Type",
			flex: 1,
			renderCell: ({ row: { type, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="whitespace-nowrap py-4 pl-4 pr-3  text-gray-900 sm:pl-6 capitalize font-bold"
					>
						{type}
					</a>
				);
			},
		},
		{
			field: "appStatus",
			headerName: "App Status",
			flex: 1,
			renderCell: ({ row: { appStatus, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className={`capitalize font-bold ${
							appStatus === "approved"
								? "text-primary-500"
								: appStatus === "rejected"
								? "text-red-700"
								: "text-gray-500"
						}`}
					>
						{appStatus}
					</a>
				);
			},
		},
		{
			field: "cmStatus",
			headerName: "CM Status",
			flex: 1,
			renderCell: ({ row: { cmStatus, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className={`capitalize font-semibold ${
							cmStatus === "approved"
								? "text-primary-500"
								: cmStatus === "rejected"
								? "text-red-700"
								: "text-gray-500"
						}`}
					>
						{cmStatus}
					</a>
				);
			},
		},
		{
			field: "coLocation",
			headerName: "Location",
			flex: 1,
			renderCell: ({ row: { coLocation, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
					>
						<MdLocationPin className="float-left text-2xl " />
						{coLocation}
					</a>
				);
			},
		},
		{
			field: "createdAt",
			headerName: "Submission Date",
			flex: 1,
			valueGetter: ({ value }) => value && format(new Date(value), "MM/dd/yyy"),
		},
		{
			field: "marketData",
			headerName: "",
			flex: 1,
			renderCell: ({ row: { orgName, _id } }) => {
				return (
					<div
						onClick={() => handleDeletePrompt(orgName, _id)}
						className="cursor-pointer material-icons-outlined text-red-700"
					>
						delete
					</div>
				);
			},
		},
	];
	const [displayPromptMessage, setDisplayPromptMessage] = useState({
		heading: "",
		body: "",
	});
	const [currentId, setCurrentId] = useState();
	const deletePromptRef = useRef(null);

	function getRowId(row) {
		return row._id;
	}

	function handleClick(id) {
		window.open(
			`/dashboard/profile/${id}`,
			"_blank",
			"rel=noopener noreferrer"
		);
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<Box sx={{ flexGrow: 1 }} />
				<GridToolbarExport
					slotprops={{
						tooltip: { title: "Export data" },
						button: { variant: "outlined" },
					}}
				/>
			</GridToolbarContainer>
		);
	}

	const [isAppsLoading, setIsAppsLoading] = useState(true);
	const [filteredApps, setFilteredApps] = useState();
	const [keyword, setKeyword] = useState("");

	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 10,
	});

	const handlePrevFetch = async () => {
		await fetchApplications(
			userState.accessToken,
			pagination.page - 1,
			keyword,
			" "
		);
	};

	const handleNextFetch = async () => {
		await fetchApplications(
			userState.accessToken,
			pagination.page + 1,
			keyword,
			" "
		);
	};

	const fetchApplications = async (accessToken) => {
		try {
			setIsAppsLoading(true);
			const response = await getApplications(accessToken);
			if (response.status === "success") {
				console.log(response);
				//const { limit, page, remaining, total, results } = response.data;
				//setPagination({ limit, page: page, remaining, total });

				setApps(response.data);
				setTableApps(response.data.slice(0, 10));
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			//	notify("something went wrong");
		} finally {
			setIsAppsLoading(false);
		}
	};

	//  const analyzeApplications = (data) => {
	//   const result = data.reduce((accumulator, application) => {
	//     const { coLocation, type, annualAward, appStatus } = application;

	//     if (!accumulator[coLocation]) {
	//       accumulator[coLocation] = {
	//        giveCount: 0,
	//        boostCount: 0,
	//        totalAward: 0,
	//        coLocation: coLocation
	//       };
	//     }

	//     if (type === 'give') {
	//       accumulator[coLocation].giveCount++;
	//     } else if (type === 'boost') {
	//       accumulator[coLocation].boostCount++;
	//     }
	//     if (annualAward >= 0 ){
	//       accumulator[coLocation].totalAward += annualAward;
	//     }
	//     return accumulator;
	//   }, {});

	//   const resultArray = Object.values(result);
	//   const sortedResult = resultArray.sort((a, b) => b.totalAward - a.totalAward);

	//    // Limit the result to only the first 10 applications
	//   const slicedResult = sortedResult.slice(0, 10);

	//   setFilteredApps(slicedResult);

	//   return sortedResult;
	// };

	const analyzeApplications = (data) => {
		const result = data.reduce((accumulator, application) => {
			const { coLocation, type, annualAward, appStatus } = application;

			if (!accumulator[coLocation]) {
				accumulator[coLocation] = {
					giveCount: 0,
					boostCount: 0,
					totalAward: 0,
					coLocation: coLocation,
				};
			}

			if (type === "give") {
				accumulator[coLocation].giveCount++;
			} else if (type === "boost") {
				accumulator[coLocation].boostCount++;
			}

			// Only include annualAward if appStatus is approved
			if (appStatus === "approved" && annualAward >= 0) {
				accumulator[coLocation].totalAward += annualAward;
			}

			return accumulator;
		}, {});

		const resultArray = Object.values(result);
		const sortedResult = resultArray.sort(
			(a, b) => b.totalAward - a.totalAward
		);

		// Limit the result to only the first 10 applications
		const slicedResult = sortedResult.slice(0, 10);

		setFilteredApps(slicedResult);

		return sortedResult;
	};

	const groupApplicationsByCause = (applications) => {
		const groupedData = {};

		applications.forEach((application) => {
			const { causes, appStatus, annualAward, type } = application;

			causes?.forEach((cause) => {
				if (type === "give") {
					if (!groupedData[cause]) {
						groupedData[cause] = {
							total: 0,
							pending: 0,
							approved: 0,
							rejected: 0,
							totalAnnualAward: 0,
						};
					}
					groupedData[cause].total += 1;

					if (appStatus === "approved") {
						groupedData[cause].totalAnnualAward += annualAward;
						groupedData[cause].approved += 1;
					} else if (appStatus === "pending") {
						groupedData[cause].pending += 1;
					} else if (appStatus === "rejected") {
						groupedData[cause].rejected += 1;
					}
				}
			});
		});

		return groupedData;
	};

	const groupApplicationsByCategory = (applications) => {
		const groupedData = {};

		applications.forEach((application) => {
			const { category, appStatus, annualAward } = application;

			if (application.type === "boost") {
				if (!groupedData[category]) {
					groupedData[category] = {
						total: 0,
						pending: 0,
						approved: 0,
						rejected: 0,
						totalAnnualAward: 0,
					};
				}

				groupedData[category].total += 1;

				if (appStatus === "approved") {
					groupedData[category].totalAnnualAward += annualAward;
					groupedData[category].approved += 1;
				} else if (appStatus === "pending") {
					groupedData[category].pending += 1;
				} else if (appStatus === "rejected") {
					groupedData[category].rejected += 1;
				}

				// switch (appStatus) {
				//   case 'pending':
				//     groupedData[category].pending += 1;
				//     break;
				//   case 'approved':
				//     groupedData[category].approved += 1;
				//     break;
				//   case 'rejected':
				//     groupedData[category].rejected += 1;
				//     break;
				//   default:
				//     break;
				// }
			}
		});

		return groupedData;
	};

	const groupedCatData = groupApplicationsByCategory(apps);
	const groupedData = groupApplicationsByCause(apps);

	const itemsPerPage = 10;

	const [currentPage, setCurrentPage] = useState(1);

	const totalPages = Math.ceil(Object.keys(groupedData).length / itemsPerPage);

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const currentData = Object.keys(groupedData).slice(startIndex, endIndex);

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	useEffect(() => {
		fetchApplications(userState.accessToken, 0, "", "");
		fetchStats();
	}, []);

	useEffect(() => {
		analyzeApplications(apps);
		groupApplicationsByCause(apps);
		groupApplicationsByCategory(apps);
	}, [apps]);

	return (
		<>
			<div className="m-10 flex items-center mt-10">
				<h1 className="ml-3 text-4xl font-bold leading-7 text-gray-900  capitalize">
					{greetings()}, {userState.firstName} {userState.lastName}!
				</h1>
				{/* <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block  px-3 py-2 border border-gray-600 rounded-md">+ Impact Report</div> */}
			</div>

			<hr className="solid " />
			{/* Stats */}
			<div className="m-10">
				{!isStatsLoading ? (
					<Stats stats={stats} />
				) : (
					<Spinner displayText="Loading..." />
				)}
			</div>

			{/* Line graph & Bar chart graph */}

			<div className="pt-5 m-10 gap-x-3 grid  md:grid-cols-2">
				<div className=" rounded-lg shadow">
					<LineGraph applications={apps} />
				</div>
				<div className="span-cols-3 rounded-lg shadow">
					<BarChart applications={apps} />
				</div>
			</div>
			<div className="m-10 flex grid md:grid-cols-6">
				<div className="max-w-4xl col-span-4">
					<div className="sm:hidden">
						<label htmlFor="tabs" className="sr-only">
							Select a tab
						</label>
						{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
						<select
							id="tabs"
							name="tabs"
							className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
							defaultValue={appTabs.find((tab) => tab.name === activeTab).name}
						>
							{appTabs.map((tab) => (
								<option key={tab.name}>{tab.name}</option>
							))}
						</select>
					</div>
					<div className="hidden sm:block">
						<div className="border-b border-gray-200">
							<nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
								{appTabs.map((tab) => (
									<div
										key={tab.name}
										onClick={() => {
											changeTab(tab.name);
										}}
										className={classNames(
											activeTab === tab.name
												? "border-purple-500 text-purple-600"
												: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
											"whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
										)}
									>
										{tab.name}
									</div>
								))}
							</nav>
						</div>
					</div>
					<Box m="5px">
						<Box
							height="75vh"
							width="full"
							sx={{
								boxShadow: 1,
								// borderColor: colors.grey[400],
								backgroundColor: "#FFFFFF",
								"& .MuiDataGrid-columnHeaders": {
									fontWeight: "bold",
								},
							}}
						>
							<DataGrid
								rows={tableApps}
								columns={columns}
								slots={{ toolbar: CustomToolbar }}
								getRowId={getRowId}
							/>
						</Box>
					</Box>
					<Prompt
						header={displayPromptMessage.heading}
						body={displayPromptMessage.body}
						ref={deletePromptRef}
						action={handleDelete}
					/>
				</div>
				<div className="mt-12 col-span-2">
					<div className="m-5 p-8 bg-white rounded  rounded-lg shadow-md shadow-slate-600">
						<h1 className="mb-7">
							<span className="text-xl font-bold text-gray-900">
								Nonprofit Causes Supported
							</span>
						</h1>
						<div className="">
							<DoughnutChart apps={apps} />
						</div>
					</div>
				</div>
			</div>
			{/* Scorecards */}

			{/* <div className="">
       <BarGraph
      applications={apps}/> 
      *
      <LineGraph2
      apps={apps}/>
  </div> */}
		</>
	);
}

export default ImpactHome;
