/* eslint-disable */
import React, {
	useState,
	useEffect,
	useCallback,
	Fragment,
	useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import { MAGIC_NUMBERS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import ApplicantCard from "./ApplicantCard";
import { debounce } from "../../services/util";
import { useParams } from "react-router-dom";
import getApplicants from "./requests/getApplicantsSearch";
import { Box } from "@mui/material";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridToolbarExport,
} from "@mui/x-data-grid";
import { MdLocationPin } from "react-icons/md";
import { format } from "date-fns";
import deleteApplication from "./requests/deleteApplication";
import Prompt from "../../components/prompt/Prompt";
import { showPrompt } from "../../services/util";
import Logo from "../../assets/NonProfit.png";
import SULogo from "../../assets/StartUp.png";

function renderApps(app, index) {
	return <ApplicantCard key={index} application={app} />;
}

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function ScholarshipApplications() {
	const { userState } = useUserContext();

	const [keyword, setKeyword] = useState("");
	const [marketData, setMarketData] = useState([]);
	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 30,
	});
	const [isStatsLoading, setIsStatsLoading] = useState(false);
	const [isApplicantsLoading, setIsApplicantsLoading] = useState(false);
	const [dashStats, setStats] = useState({});
	const award = dashStats.annualAward;
	const giveAward = dashStats.annualGiveAward;
	const boostAward = dashStats.annualBoostAward;
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const [marketLocation, setMarketLocation] = useState("");
	const [displayPromptMessage, setDisplayPromptMessage] = useState({
		heading: "",
		body: "",
	});
	const [currentId, setCurrentId] = useState();
	const deletePromptRef = useRef(null);

	function getRowId(row) {
		return row._id;
	}

	function handleClick(id) {
		window.open(
			`/dashboard/profile/${id}`,
			"_blank",
			"noopener,noreferrer"
		);
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<Box sx={{ flexGrow: 1 }} />
				<GridToolbarExport
					slotprops={{
						tooltip: { title: "Export data" },
						button: { variant: "outlined" },
					}}
				/>
			</GridToolbarContainer>
		);
	}

	const handleDeletePrompt = (orgName, id) => {
		showPrompt(
			"Delete Application",
			`Are you sure you want to delete "${orgName}'s" application? This action cannot be undone.`,
			deletePromptRef,
			"open",
			setDisplayPromptMessage
		);
		setCurrentId(id);
	};

	const handleDelete = async () => {
		const response = await deleteApplication(currentId, userState.accessToken);
		if (response.status === "success") {
			notify("Application successfully deleted", "info");
			deletePromptRef.current.closePrompt();
			window.location.reload(true);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
	};

	const columns = [
		{
			field: "orgName",
			headerName: "Organization",
			flex: 2,
			renderCell: ({
				row: { logoImage, orgName, firstName, lastName, _id, type },
			}) => {
				return (
					<a href={`/dashboard/profile/${_id}`} target="_blank" rel="noopener noreferrer">
						<div className="float-left">
							<div className="text-center ">
								{logoImage?.small?.url ? (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={logoImage.small.url}
									/>
								) : logoImage?.logoImage?.url ? (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={logoImage.logoImage.url}
									/>
								) : (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={type === "give" ? Logo : SULogo}
									/>
								)}
							</div>
						</div>
						<div className="float-left ml-3 font-bold">
							<h1>{orgName}</h1>
							<p className="text-gray-500">
								{firstName} {lastName}
							</p>
						</div>
					</a>
				);
			},
		},
		{
			field: "type",
			headerName: "Scholarship Type",
			flex: 1,
			renderCell: ({ row: { type, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="whitespace-nowrap py-4 pl-4 pr-3  text-gray-900 sm:pl-6 capitalize font-bold"
					>
						{type}
					</a>
				);
			},
		},
		{
			field: "appStatus",
			headerName: "App Status",
			flex: 1,
			renderCell: ({ row: { appStatus, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className={`capitalize font-bold ${
							appStatus === "approved"
								? "text-primary-500"
								: appStatus === "rejected"
								? "text-red-700"
								: "text-gray-500"
						}`}
					>
						{appStatus}
					</a>
				);
			},
		},
		{
			field: "cmStatus",
			headerName: "CM Status",
			flex: 1,
			renderCell: ({ row: { cmStatus, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className={`capitalize font-semibold ${
							cmStatus === "approved"
								? "text-primary-500"
								: cmStatus === "rejected"
								? "text-red-700"
								: "text-gray-500"
						}`}
					>
						{cmStatus}
					</a>
				);
			},
		},
		{
			field: "coLocation",
			headerName: "Location",
			flex: 1.5,
			renderCell: ({ row: { coLocation, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
					>
						<MdLocationPin className="float-left text-2xl " />
						{coLocation}
					</a>
				);
			},
		},
		{
			field: "createdAt",
			headerName: "Submission Date",
			flex: 1,
			valueGetter: ({ value }) => value && format(new Date(value), "MM/dd/yyy"),
		},
		{
			field: "marketData",
			headerName: "",
			flex: 1,
			renderCell: ({ row: { orgName, _id } }) => {
				return (
					<div
						onClick={() => handleDeletePrompt(orgName, _id)}
						className="cursor-pointer material-icons-outlined text-red-700"
					>
						delete
					</div>
				);
			},
		},
	];

	const handlePrevFetch = async () => {
		await fetchApplicants(
			userState.accessToken,
			pagination.page - 1,
			keyword,
			" "
		);
	};

	const handleNextFetch = async () => {
		await fetchApplicants(
			userState.accessToken,
			pagination.page + 1,
			keyword,
			" "
		);
	};

	//Search info
	const fetchApplicants = async (accessToken, page, _keyword) => {
		setIsApplicantsLoading(true);
		const response = await getApplicants(
			{
				page,
				keyword: _keyword,
				limit: pagination.limit,
			},
			accessToken
		);
		if (response.status === "success") {
			const { limit, page, remaining, total, results } = response.data;
			console.log(results);
			setPagination({ limit, page: page, remaining, total });
			setMarketData(results);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsApplicantsLoading(false);
	};

	const handleSearch = (event) => {
		const query = event.target.value;
		fetchApplicants(userState.accessToken, 0, query);
		setKeyword(query);
	};

	const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

	useEffect(() => {
		fetchApplicants(userState.accessToken, 0, "", "");
	}, []);

	return (
		<>
			<div className=" mt-3 mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
				<div className="mt-5">
					<h1>
						<span className="text-2xl font-bold text-gray-900">
							All COhatch Scholarships
						</span>
					</h1>
				</div>
				<div className="w-full mt-12 mb-12 flex flex-col sm:flex-row justify-between">
					<input
						type="text"
						name="search"
						onInput={debouncedHandleSearch}
						placeholder="Search Applicants"
						className="block border border-gray-400 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-full sm:max-w-md sm:text-base"
					/>
				</div>
				<Box m="5px">
					<Box
						height="75vh"
						width="full"
						sx={{
							boxShadow: 1,
							// borderColor: colors.grey[400],
							backgroundColor: "#FFFFFF",
							"& .MuiDataGrid-columnHeaders": {
								fontWeight: "bold",
							},
						}}
					>
						<DataGrid
							//onRowClick={handleClick}
							rowHeight={80}
							rows={marketData}
							columns={columns}
							slots={{ toolbar: CustomToolbar }}
							//  components={{ Toolbar: GridToolbar }}
							getRowId={getRowId}
						/>
					</Box>
				</Box>

				<Prompt
					header={displayPromptMessage.heading}
					body={displayPromptMessage.body}
					ref={deletePromptRef}
					action={handleDelete}
				/>
			</div>
		</>
	);
}

export default ScholarshipApplications;
