/*eslint-disable*/
import React, {useEffect, useState} from "react";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams, useHistory } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import getAppById from "./requests/getAppById";
import "./stepper.css";
import Spinner from "../../components/spinner/Spinner";
import EditApplicationModal from "./EditApplicantModal";
import getAppNotifications from "./requests/getAppNotifications";
import updateApplication from "./updateApplication";
import notify from "../../services/toast";
import { Link } from "react-router-dom";
import { MAGIC_NUMBERS } from "../../constant";
import Logo from "../../assets/NonProfitmed.png";
import SULogo from "../../assets/StartUp.png";
import FileUploader from "../../components/file_uploader/FileUploader";
import Modal from "../../components/modal/Modal";
import ButtonPrimary from "../../components/button/ButtonSecondary";
import SelectInput from "../../components/select_input/SelectInput";
import {format} from "date-fns";
import ApplicationFile from "./ApplicationFile";
import config from "../../config";
import getAppsByUserId from "./requests/getAppsByUserId";
import { filter } from "lodash";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function ApplicantProfile({}) {
const [notification, setNotification] = useState("");
const [showApplicantModal, setShowApplicantModal] = useState(false);
const [showUploadFile, setShowUploadFile] = useState(false);
const {userState} = useUserContext();
const [appApprovedModal, setAppApprovedModal] = useState(false);
const [appDeniedModal, setAppDeniedModal] = useState(false);
const [denyReasonModal, setDenyReasonModal] = useState(false);
const [application, setApplication] = React.useState({}); 
const [apps, setApps] = useState("");
const app = application;
const params = useParams();
const navigate = useNavigate();
const [idFile, setIdFile] = useState("");

 const [isContacted, setIsContacted] = useState(false);
 const [isModalOpen, setIsModalOpen] = useState(false);
 const [scoreMemAwarded, setScoreMemAwarded] = useState();
const [awardedMember, setAwardedMember] = useState();
  
 const [tabs, setTabs] = useState([
		{ name: "Overview" },
		{ name: "Scorecard" },
    { name: "Application"}
	]);

  const [tabYears, setTabYears] = useState([
   { name: "2023"},
   {name: "2024"}
  ]);
  
 

  const [selectedTab, setSelectedTab] = useState("Overview");
  const initialState = apps && apps[0].createdAt;
  
  const [selectedTabYear, setSelectedTabYear] = useState(initialState);

  console.log("initialState", initialState, selectedTabYear);
 // const [selectedTabYear, setSelectedTabYear] = useState(apps && apps.length > 0 ? apps[0].createdAt : null);
  

  const changeTab = (year) => {
    setSelectedTab(year);
  };

  // Get years for applications
  const tabYear = [... new Set(apps ? apps.map(app => app.createdAt) : null)]
  const filteredApplication = apps ? apps.filter(app => app.createdAt === selectedTabYear) : null;
  const selectedApp = filteredApplication ? filteredApplication[0] : null
  const createdAt = selectedApp?.createdAt ? selectedApp?.createdAt : null;
  var submissionDate = new Date(createdAt);
  function addYears(date, years) {
		date.setFullYear(date.getFullYear() + years);
		return date;
	}



var newDate = addYears(submissionDate, 1);
    console.log(selectedApp);

  const orgAmountOPTIONS = [
    { value: 0, label: "0" },
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" }
	];

  const scorecardOPTIONS = [
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
	];
  	const statusOPTIONS = [
		{ value: "rejected", label: "Rejected" },
		{ value: "approved", label: "Approved" },
	];
  const appOPTIONS = [
		{ value: "2023", label: "2023" },
		{ value: "2024", label: "2024" },
	];
  const [appYear, setAppYear] = useState();
   const denyReasons = [
    {value: "At Capacity", label: "Location is at capacity"},
    {value: "Previous Member", label: "Applicant is a previous COhatch member"},
    {value: "Current Member", label: "Applicant is a current COhatch member"},
    {value: "Poor Fit", label: "Applicant was a Poor Fit"}
  ];

  const [amount, setAmount] = React.useState("");
  
// PAYLOAD 
const handleSaveFile = (payload) => {
  const newIdNumber = {
    idOption: "Yes",
    idFile: idFile,
    idReason: null
  }
  setPayload({
    ...payload,
     idNumber: newIdNumber
  });
  handleSubmit(payload);
  setShowUploadFile(false);
};

  const handleChange = (key, value) => {
		setPayload({
			...payload,
			[key]: value,
		});
	};
  const handleUpdate = (payload) => {
		if ( payload.mlStatus === "approved") {
    setPayload({
        ...payload,
        appStatus: "approved",
      });
      setAppApprovedModal(true);
		} else if ( payload.cmStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} 
    else if ( payload.mlStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else {
			setPayload({
				...payload,
				appStatus: "pending",
			});
			handleSubmit(payload);
		}
	}

   const handleCharlotteAnnualAward = (evt) => {
	  const amount = evt.value * 1308;
    const members = evt.value;
		setAmount(amount);
    setScoreMemAwarded(members);
    setAwardedMember(members);
		handleChange("annualAward", amount);
	}

  // Updating Application
	const [payload, setPayload] = React.useState({
		firstName: selectedApp?.firstName,
		lastName: selectedApp?.lastName,
		email: selectedApp?.email,
		phoneNumber: selectedApp?.phoneNumber,
		cmStatus: selectedApp?.cmStatus,
		mlStatus: selectedApp?.mlStauts,
		appStatus: selectedApp?.appStatus,
		officeRnd: selectedApp?.officeRnd,
		scoreMission: selectedApp?.scoreMission,
		scoreImpact: selectedApp?.scoreImpact,
		scoreFit: selectedApp?.scoreFit,
		scoreMemAwarded: selectedApp?.scoreMemAwarded,
		annualAward: selectedApp?.annualAward,
    denyReason: selectedApp?.denyReason,
    notes: selectedApp?.notes,
    awardedMember: selectedApp?.awardedMember,
    market: selectedApp?.market,
    coLocation: selectedApp?.coLocation,
    status501: selectedApp?.status501,
    dateFounded: selectedApp?.dateFounded,
    idNumber: selectedApp?.idNumber,
    applicantContacted: selectedApp?.applicantContacted
	});
  const handleApproveUpdate = () => {
		handleSubmit(payload);
		setAppApprovedModal(false);
    //add confetti
	}
  const handleDenyUpdate = () => {
  setAppDeniedModal(false);
  setDenyReasonModal(true);
  //add confetti
	}
  const handleDenyPayload = () => {
    handleSubmit(payload);
    setDenyReasonModal(false);
  }
	const handleGoBack = () => {
		setAppApprovedModal(false);
    setAppDeniedModal(false);
	}
  const handleAnnualAward = (evt) => {
	  const amount = evt.value * 2388;
    const members = evt.value;
		setAmount(amount);
    setScoreMemAwarded(members);
    setAwardedMember(members);
		handleChange("annualAward", amount);
	};

  const handleClick = () => {
    // Toggle the state when the button is clicked
    setContacted({
			...contacted,
			applicantContacted: "Yes",
		});
    setIsModalOpen(true);
  };

   const handleConfirm = () => {
    // Toggle the state and close the modal when confirmed
    setIsContacted(!isContacted);
    setPayload({
      ...payload,
      applicantContacted: "Yes"
    });
    handleSubmit(payload)
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    // Close the modal when canceled

    setIsContacted(!isContacted);
    setIsModalOpen(false);
  };


const backgroundCriteria = { requiredSize: null, requiredDimensions: { width: 0, height: 0 } };

function PhotoDisplay({ callback }) {
  return <DocumentPlusIcon onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

const [contacted, setContacted] = useState({
  idNumber: {
    idFile
  },
  applicantContacted: application.applicantContacted
});
 
function handleFileClick() {
  let path = `/impact/all_scholarships/file/${application.id}`
  navigate(path);
};

function renderCause(cause, index) {
      return <div
      index={index}
      className="inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 "
    >
      {cause}
      </div>
    };

 function renderCause(cause, index) {
      return <div
      index={index}
      className="inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 "
    >
      {cause}
      </div>
    };

 const fetchApplication = async () => {
    try {
      const response = await getAppById(userState.accessToken, params.applicationId);
      if (response.status === "success") {
        setApplication(response.data);
        fetchApplicationByUserId(userState.accessToken, response.data.userId)
      } else {
 
      }
    } catch (error) {
    }
  };

  const fetchApplicationByUserId = async (accessToken, userId) => {
    try {
      const response = await getAppsByUserId(accessToken, userId);
      if (response.status === "success") {
      setApps(response.data);
      } else {
 
      }
    } catch (error) {
    }
  };


  const fetchNotifications = async () => {
  try {
      const response = await getAppNotifications(userState.accessToken, params.applicationId);
     
      if (response.status === "success") {
       
        const { data } = response;
       
        setNotification(data);
      } else {
 
      }
    } catch (error) {
    }
  };

  const handleReload = async () => {
    await fetchApplication();
  };

 const handleIdFileUpload = (result) => {
     setIdFile({
      idFile: result
    });
    //console.log(idFile, "idfile");
   };
const [loader, setLoader] = useState(false);
const handleDocumentUpload = (async (data) => {
 // console.log(data.target.files[0], "data");
  try {
    setLoader(true);
  const file = data.target.files[0]
  const folder = "501c3_photos"
  const formData = new FormData();
  formData.append("width", 0);
  formData.append("height", 0);
  formData.append("folder", folder);
  formData.append("file", file);
  formData.append("name", file.name);
 const BASE_API_URL = config["API_BASE_ENDPOINT"];
   const response = await fetch(`${BASE_API_URL}/admin/uploads/files`, {
    method: "POST",
    body: formData,
    headers: {
    Authorization: `Bearer ${userState.accessToken}`,
  },
  });
   if (response.ok) {
    const jsonResponse = await response.json();
    handleIdFileUpload(jsonResponse.data);
  } else {
    notify("An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.", "error");
    return;
  }
} catch (error) {
  console.log(error, "error");
      // console.log("Something went wrong. Please try again later.", "error");
    } finally {
      setLoader(false)
      console.log("done");
    }
});
  const handleSubmit = async (payload) => {
     try {
      const response = await updateApplication(userState.accessToken, payload,
      params.applicationId);
      if (response.status === "success") {
        console.log(response);
        notify("Applicaton was successfully updated.", "info");
        handleReload();
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      console.log(error);
      
      notify("Something went wrong. Please try again later.", "error");
    }
  };
//    React.useEffect(() => {
//   setPayload({
// 			...payload,
// 			market: market,
// 		});
// }, [market]);
React.useEffect(() => {
  setPayload({
			...payload,
			scoreMemAwarded: scoreMemAwarded,
		});
}, [scoreMemAwarded]);
React.useEffect(() => {
  setPayload({
			...payload,
			awardedMember: awardedMember,
		});
}, [awardedMember]);


React.useEffect(() => {
  setPayload({
			...payload,
			scoreMemAwarded: scoreMemAwarded,
		});
}, [scoreMemAwarded]);
React.useEffect(() => {
  setPayload({
			...payload,
			awardedMember: awardedMember,
		});
}, [awardedMember]);

    useEffect(() => {
    fetchApplication();
    fetchNotifications();
    }, []);

    useEffect(() => {
      if (!apps?.length) return;
      setSelectedTabYear(apps[0].createdAt);
    }, [apps]);
  
 


  return (
  <>
  {appApprovedModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center">
									<h1>
										Are you sure you would like to approve this application? Approving this application will notify the  applicant that they have been rewarded a scholarship.
									</h1>
									<ButtonPrimary size="md" text="Approve" action={handleApproveUpdate} />
									<button className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500" size="md" onClick={handleGoBack}>Go Back</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
       {appDeniedModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center ">
									<h1 className="m-10">
										Are you sure you would like to reject this application? Rejecting this application will send an email to the applicant informing them that they have not been chosen to receive a scholarship. 
									</h1>
									<button className="w-full cursor-pointer relative rounded shadow-sm px-2.5 py-2 text-white bg-red-700 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
                  size="md" onClick={handleDenyUpdate} >Reject</button>
									<button className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500" size="md" onClick={handleGoBack}>Go Back</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
      {denyReasonModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center ">
									<h1 className="m-10">
										Please select the reason you are rejecting this application.
									</h1>
                  	<div className=" w-full">
                      <div className="w-full mb-5">
                    <SelectInput
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md capitalize"
                    placeholder="Select Reason"
                    options={denyReasons}
                    setSelectedValue={(evt) => handleChange("denyReason", evt.value)}
                    
                  />
                 </div>
                 {payload.denyReason && (
                  <div className="w-full">
									<button className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
                  size="md" onClick={handleDenyPayload} >Submit</button>
									</div>
                 )}
                  </div>
                  
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
     {/* Header Section */}
    <div>
        <img 
        className="rounded h-32 w-full object-cover " 
        src= "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80" alt="" />
      <div className="flex justify-center  mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className=" flex justify-right mt-12 sm:-mt-16  sm:space-x-5">
          <div>
            <div>
              {selectedApp?.logoImage?.small?.url ?  <img
                className="ml-12 h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                  src={selectedApp?.logoImage.small.url}
                />
                  : selectedApp?.logoImage?.logoImage?.url ? <img
                className="ml-12 h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                  src={selectedApp?.logoImage.logoImage.url}
                /> : <img
                className="ml-12 h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                  src={selectedApp?.type === "give" ? Logo : SULogo}
                />}
                 <div className="mt-6 flex flex-col justify-stretch space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
            <h1 className="ml-7 mt-3 truncate text-2xl font-bold text-gray-900">{selectedApp?.firstName} {selectedApp?.lastName}
            </h1>
            <div>
              { selectedApp?.appStatus === "pending" ?
                <div className="mt-3 inline-flex justify-center rounded-md bg-gray-400/80 px-3 py-2 text-sm font-semibold ">
              <span className="font-black">Pending</span>
              </div> :
              selectedApp?.appStatus === "rejected" ?
              <div className="mt-3 inline-flex justify-center rounded-md bg-red-600/80 px-3 py-2 text-sm font-semibold ">
             Rejected
              </div> :
              selectedApp?.appStatus === "approved" ?
              <div className="mt-3 inline-flex justify-center rounded-md bg-green-400/80 px-3 py-2 text-sm font-semibold ">
               Active
              </div> :
              null
              }
           
              </div>
            </div>
            
              {/* <div className="mt-6 flex flex-col justify-stretch space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => setShowApplicantModal(true)}
              >
              <span>View Application</span>
              </button>
              <EditApplicationModal
                open={showApplicantModal}
                onClose={() => setShowApplicantModal(false)}
                app={application}
                handleSubmit={handleSubmit}
              /> 
              
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Application Year Nav */}
     <section>
      {/* Tabs */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
         // defaultValue={activeTab}
        >
          {tabYear.map((year) => (
            <option key={year}
            onClick={() => {
              changeTab(year);
            }}>
             {format(new Date(year), "yyyy")}
              </option>
          ))}
        </select>
      </div>
      <div className="pl-10 hidden sm:block">
        <div className="border-b">
          <nav className="" aria-label="Tabs">
            <div className="flex justify-end">
            {tabYear.map((year) => (
              <div
                key={year}
                onClick={() => {
                  setSelectedTabYear(year);
                }}
                className={classNames(
                  selectedTabYear === year
                    ? "border-success-800 text-success-800"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                )}
              >
                {format(new Date(year), "yyyy")}
              </div>
            ))}
            {/* <div className="float-right ml-48 w-36">
            <SelectInput
              className="appearance-none pb-10 mb-5 block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
              options={appOPTIONS}
              // placeholder="Select an option"
              setSelectedValue={(evt) => { setAppYear(evt.value) }}
              defaultValue="2023 "
              //placeholder="2023"
            />
          </div> */}
            </div>
          </nav>
        </div>
      </div>
    </section>

    {/*Page Nav */}
    <section>
      {/* Tabs */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.name === selectedTab).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="pl-10 hidden sm:block">
        <div className="">
          <nav className="mt-2 -mb-px flow-root space-x-8" aria-label="Tabs">
            <div className="flex">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => {
                  setSelectedTab(tab.name);
                }}
                className={classNames(
                  selectedTab === tab.name
                    ? "bg-indigo-100 text-indigo-700" : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium"
                )}
              >
                {tab.name}
                {tab.info ? (
                  <span
                    className={classNames(
                      tab.name === selectedTab ? "bg-purple-100 text-purple-600" : "bg-gray-100 text-gray-900",
                      "hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {tab.info}
                  </span>
                ) : null}

              </div>
            ))}
            {/* <div className="float-right ml-48 w-36">
            <SelectInput
              className="appearance-none pb-10 mb-5 block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
              options={appOPTIONS}
              // placeholder="Select an option"
              setSelectedValue={(evt) => { setAppYear(evt.value) }}
              defaultValue="2023 "
              //placeholder="2023"
            />
          </div> */}
            </div>

          </nav>
          
        </div>
         
      </div>
    </section>

    <div className="ml-10 mr-10  grid grid-cols-6 gap-4 mb-16">
    {selectedTab === "Overview" && 
    <div className="mt-10 col-span-4">
      <div className="grid grid-cols-1 divide-y">
      <div className="mb-3">
        <h1 className="font-bold text-xl">
         Causes & Tags
        </h1>
        <div className="mt-7">
           <div
            className="mr-2 inline-flex justify-center rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 "
          >
          <span className="capitalize">{selectedApp?.type} Scholar</span>
          </div>

            {selectedApp?.causes?.map((cause, index) => {
            return renderCause(cause, index);
          })}
          {selectedApp?.category && (
            <div
            className="mr-2 inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 "
          >
          <span className="capitalize">{selectedApp?.category}</span>
          </div>
          )}
        </div>
      </div>
      <div>
       <div>
        <h1 className="mt-3 mb-3 font-bold text-xl">
          About {selectedApp?.orgName}
        </h1>
        <p className="mb-4">
          {selectedApp?.orgMission}
        </p>
       </div>
      </div>
      {/* Info Cards */}
      </div>
        <div className="mt-4 mb-15 ">
        <ul role="list" className=" grid grid-cols-2 gap-6 ">
       {selectedApp?.type === "give" && (
         <li key={selectedApp?.email} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className=" w-full items-center justify-between space-x-6 p-6">
            <div className=" ">
              <div className="flex items-center space-x-3 divide-y divide-gray-200">
                <h3 className=" text-sm font-medium text-primary-500">501C3 Document</h3>
              </div>
          
            <div>
            {selectedApp?.idNumber.idReason === "No" && (
              <>
              <h3 className=" text-sm font-medium ">Reason organization does not have a 501C3:</h3>
            <p className="mt-1 text-sm text-gray-500">{selectedApp?.idNumber.idReason}</p> 
            </>
            )}
            
             
             
            {showUploadFile && (
           		<Modal>
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                  </span>
                  <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
                    <div className="w-full flex justify-between items-center">
                    <div>
          
									<button 
                  className="cursor-pointer"
                  onClick={() => setShowUploadFile(false)}
                  >
										<span className="material-icons-outlined text-4xl">
											chevron_left
										</span>
									</button>
                      <p className="mb-3">Upload Document</p>
                      <input
                      onChange={handleDocumentUpload} 
                        type="file"
                        id="giveDocument"
                        />
                    </div>
                    <div>
                      <button className=" relative rounded shadow-sm px-1.5 py-1.5 text-white  bg-success-800"
                      onClick={() => {
                  handleSaveFile(payload);
                }}
                disabled={!(loader===false)}>
                       {loader ? ( <span>Uploading..</span>) : (<span>Save File</span>)}</button>
                       
                    </div>
                    </div>
                  </div>
                </div>
              </div>
				</Modal>
            )}
       
            </div>
            </div>
          </div>
          
          <div className="pl-2 pr-4 flex justify-between">
            <div className="">
               <button className="cursor-pointer px-1 py-1  "
            size="md" 
            onClick={() => setShowUploadFile(true)}>Upload</button>
            </div>
            <div className="px-1 py-1 ">
           {selectedApp?.idNumber.idFile?.large?.url ? 
           <div className="justify-end">
           <Link
           to={`/impact/501file/${selectedApp?.id}`}
           target="_blank">View</Link>
           </div>
            : selectedApp?.idNumber.idFile?.idFile?.url ?
             <Link
           to={selectedApp?.idNumber.idFile.idFile.url}
           target="_blank">Download</Link> : null}
          </div>
          </div>
        </li>
       )}
       {selectedApp?.type === "boost" && selectedApp?.businessPdf ? (
        <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-primary-500">Proof of Business</h3>
              </div>
            </div>
          </div>
          <div className="flex justify-end pr-4">
           <Link
           to={selectedApp?.businessPdf.businessPdf.url}
           target="_blank">Download</Link>
          </div>
        </li>
       ): null}
           <li  className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-primary-500">2023 Application File</h3>
              </div>
              {/* <p className="mt-1 truncate text-sm text-gray-500">hello</p> */}
            </div>
          </div>
          <div className="flex justify-end pr-4">
           <Link
           to={`/file/${selectedApp?.id}`}
           target="_blank">View</Link>
          </div>
        </li>
    </ul>
      </div>
      <div className="mt-10 mb-10">
      <h1 className="font-bold text-black mb-4">Impact Stories from {selectedApp?.orgName}</h1>
      <p>No Submitted Stories</p>
      </div>
    </div>
    }



    {/* SCORECARD */}
      {selectedTab === "Scorecard" && (
     <div className="mt-10 col-span-4 w-3/4">
      <div className="">
        <h1 className="mb-4 font-bold text-xl">
          Scorecard
        </h1>

      {selectedApp?.type === "give" &&  selectedApp?.market != "charlotte" && (
        <>
          <div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="mt-2 w-full">
              <label
                htmlFor="orgMission"
                className="block text-md font-medium text-gray-700 mb-4"
              >
                Description of Your Organization and Mission:
              </label>
              <div className="mt-1">
                <textarea
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                  defaultValue={selectedApp?.orgMission}
                />
              </div>
            </div>
            <div className=" w-full">
              <label
                htmlFor="facebook"
                className="block text-md font-medium text-gray-700 mb-4 mt-4"
              >
                Clear Mission (1-3 Scale)
              </label>
              <div className="mt-1">
                <SelectInput
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                  options={scorecardOPTIONS}
                  placeholder={selectedApp?.scoreMission}
                  defaultValue={selectedApp?.scoreMission}
                  setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}
                />
              </div>
            </div>
          </div>
          <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="orgAlign"
                className="block text-md font-medium text-black-700"
              >
                What aspect of COhatch's mission most aligns with this organization's mission?:
              </label>
              <div className="mt-1">
                <textarea
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                  defaultValue={selectedApp?.orgAlign}
                />
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="linkedin"
                className="block text-md font-medium text-gray-700 mb-4 mt-4"
              >
                COhatch Fit (1-3 Scale)
              </label>
              <div className="mt-1">
                <SelectInput
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                  options={scorecardOPTIONS}
                  value={selectedApp?.scoreFit}
                  placeholder={selectedApp?.scoreFit}
                  setSelectedValue={(evt) => handleChange("scoreFit", evt.value)}
                />
              </div>
            </div>
          </div>

          <div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
            <div className="w-full ">
              <label
                htmlFor="orgMission"
                className="text-md font-medium text-black-700"
              >
                How does your organization impact the local community?
              </label>
              <div className="">
                <textarea
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                  defaultValue={selectedApp?.orgImpact}
                />
              </div>
            </div>

            <div className="w-full">
              <div className="w-full">
                <label
                  htmlFor="facebook"
                  className="block text-md font-medium text-gray-700 mb-4 mt-4"
                >
                  Community Impact (1-3 Scale)
                </label>
                <div className="mt-1">
                  <SelectInput
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                    options={scorecardOPTIONS}
                    placeholder={selectedApp?.scoreImpact}
                    value={selectedApp?.scoreImpact}
                    setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
            </div>
          </div>
          <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="facebook"
                className="block text-sm font-medium text-gray-700"
              >
                # of Team Members Applying for Scholarship
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                  placeholder={selectedApp?.orgAmount}
                />
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="linkedin"
                className="block text-sm font-medium text-gray-700"
              >
                # of Team Members Awarded Scholarship (5 max)
              </label>
                <div className="mt-1">
                <SelectInput
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                  setSelectedValue={handleAnnualAward}
                  options={orgAmountOPTIONS}
                  placeholder={selectedApp?.awardedMember ? selectedApp?.awardedMember : selectedApp?.scoreMemAwarded}
                />
              </div>
            </div>
          </div>
          <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="score"
                className="block text-sm font-medium text-gray-700"
              >
                Total Score (9 maximum)
              </label>
              <div className="mt-1">
                <input
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                  name="score"
                  id="score"
                  placeholder={selectedApp?.scoreFit + selectedApp?.scoreMission + selectedApp?.scoreImpact}
                //  value={payload.scoreFit + payload.scoreMission + app.scoreImpact}
                />
              </div>
            </div>

            <div className="w-full">

              <label
                htmlFor="awardAmount"
                className="block text-sm font-medium text-gray-700"
              >
                Annual Award ($199 x member for 12 months)
              </label>
              <div className="mt-1">
                <input
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                  type="awardAmount"
                  name="awardAmount"
                  id="awardAmount"
                  value={amount}
                  placeholder={selectedApp?.annualAward}
                />
              </div>
            </div>

          </div>
        </>
      )}
       {selectedApp?.type === "give" &&  selectedApp?.market === "charlotte" && (
        <>
          <div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="mt-2 w-full">
              <label
                htmlFor="orgMission"
                className="block text-md font-medium text-gray-700 mb-4"
              >
                Description of Your Organization and Mission:
              </label>
              <div className="mt-1">
                <textarea
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                  defaultValue={selectedApp?.orgMission}
                />
              </div>
            </div>
            <div className=" w-full">
              <label
                htmlFor="facebook"
                className="block text-md font-medium text-gray-700 mb-4 mt-4"
              >
                Clear Mission (1-3 Scale)
              </label>
              <div className="mt-1">
                <SelectInput
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                  options={scorecardOPTIONS}
                  placeholder={selectedApp?.scoreMission}
                  defaultValue={selectedApp?.scoreMission}
                  setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}
                />
              </div>
            </div>
          </div>
          <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="orgAlign"
                className="block text-md font-medium text-black-700"
              >
                What aspect of COhatch's mission most aligns with this organization's mission?:
              </label>
              <div className="mt-1">
                <textarea
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                  defaultValue={selectedApp?.orgAlign}
                />
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="linkedin"
                className="block text-md font-medium text-gray-700 mb-4 mt-4"
              >
                COhatch Fit (1-3 Scale)
              </label>
              <div className="mt-1">
                <SelectInput
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                  options={scorecardOPTIONS}
                  value={selectedApp?.scoreFit}
                  placeholder={selectedApp?.scoreFit}
                  setSelectedValue={(evt) => handleChange("scoreFit", evt.value)}
                />
              </div>
            </div>
          </div>

          <div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
            <div className="w-full ">
              <label
                htmlFor="orgMission"
                className="text-md font-medium text-black-700"
              >
                How does your organization impact the local community?
              </label>
              <div className="">
                <textarea
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                  defaultValue={selectedApp?.orgImpact}
                />
              </div>
            </div>

            <div className="w-full">
              <div className="w-full">
                <label
                  htmlFor="facebook"
                  className="block text-md font-medium text-gray-700 mb-4 mt-4"
                >
                  Community Impact (1-3 Scale)
                </label>
                <div className="mt-1">
                  <SelectInput
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                    options={scorecardOPTIONS}
                    placeholder={selectedApp?.scoreImpact}
                    value={selectedApp?.scoreImpact}
                    setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
            </div>
          </div>
          <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="facebook"
                className="block text-sm font-medium text-gray-700"
              >
                # of Team Members Applying for Scholarship
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                  placeholder={selectedApp?.orgAmount}
                />
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="linkedin"
                className="block text-sm font-medium text-gray-700"
              >
                # of Team Members Awarded Scholarship (5 max)
              </label>
                <div className="mt-1">
                <SelectInput
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                  setSelectedValue={handleCharlotteAnnualAward}
                  options={orgAmountOPTIONS}
                  placeholder={selectedApp?.awardedMember ? selectedApp?.awardedMember : selectedApp?.scoreMemAwarded}
                />
              </div>
            </div>
          </div>
          <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="score"
                className="block text-sm font-medium text-gray-700"
              >
                Total Score (9 maximum)
              </label>
              <div className="mt-1">
                <input
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                  name="score"
                  id="score"
                  placeholder={selectedApp?.scoreFit + selectedApp?.scoreMission + selectedApp?.scoreImpact}
                //  value={payload.scoreFit + payload.scoreMission + app.scoreImpact}
                />
              </div>
            </div>

            <div className="w-full">

              <label
                htmlFor="awardAmount"
                className="block text-sm font-medium text-gray-700"
              >
                Annual Award ($109 x member for 12 months)
              </label>
              <div className="mt-1">
                <input
                  disabled
                  className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                  type="awardAmount"
                  name="awardAmount"
                  id="awardAmount"
                  value={amount}
                  placeholder={selectedApp?.annualAward}
                />
              </div>
            </div>

          </div>
        </>
      )}
      {selectedApp?.type === "boost" && selectedApp?.market != "charlotte" && (
        <>
        {selectedApp?.orgMission ? (
          <div>
            <div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="mt-2 w-full">
												<label
													htmlFor="orgMission"
													className="block text-md font-medium text-gray-700 mb-4"
												>
													Description of Your Organization and Mission:
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={selectedApp?.orgMission}
													/>
												</div>
											</div>

											<div className="w-full">
												<label
													htmlFor="facebook"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
												Clear Mission (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														placeholder={selectedApp?.scoreMission}
														defaultValue={selectedApp?.scoreMission}
														setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="orgAlign"
													className="block text-md font-medium text-black-700"
												>
													How will using COhatch's locations and resources help you reach your goals?
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={selectedApp?.orgUses}
													/>
												</div>
											</div>
											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													COhatch Fit (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														value={selectedApp?.scoreFit}
														placeholder={selectedApp?.scoreFit}
														setSelectedValue={(evt) => handleChange("scoreFit", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
											<div className="w-full ">
												<label
													htmlFor="orgMission"
													className="text-md font-medium text-black-700"
												>
													What social & economic impact does your company have on the local community?
												</label>
												<div className="">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={selectedApp?.orgAlign}
													/>
												</div>
											</div>

											<div className="w-full">
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-md font-medium text-gray-700 mb-4 mt-4"
													>
														Community Impact (1-3 Scale)
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={scorecardOPTIONS}
															placeholder={selectedApp?.scoreImpact}
															value={selectedApp?.scoreImpact}
															setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
														/>
													</div>
												</div>
											</div>
										</div>

										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="facebook"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Applying for Scholarship
												</label>
												<div className="mt-1">
													<input
														type="number"
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														placeholder={selectedApp?.orgAmount}
													/>
												</div>
											</div>
											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Awarded Scholarship (5 max)
												</label>
													<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														setSelectedValue={handleAnnualAward}
														options={orgAmountOPTIONS}
														placeholder={selectedApp?.awardedMember ? selectedApp?.awardedMember : selectedApp?.scoreMemAwarded}
													/>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="score"
													className="block text-sm font-medium text-gray-700"
												>
													Total Score (9 maximum)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														name="score"
														id="score"
														placeholder={selectedApp?.scoreFit + selectedApp?.scoreMission + selectedApp?.scoreImpact}
													//  value={payload.scoreFit + payload.scoreMission + app.scoreImpact}
													/>
												</div>
											</div>

											<div className="w-full">

												<label
													htmlFor="awardAmount"
													className="block text-sm font-medium text-gray-700"
												>
													Annual Award ($199 x member for 12 months)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														type="awardAmount"
														name="awardAmount"
														id="awardAmount"
														value={amount}
                            placeholder={selectedApp?.annualAward}
													/>
												</div>
											</div>

										</div>

          </div>
        ):  <>
         <div className="w-full mb-7">
            <label
              htmlFor="linkedin"
              className="text-md font-medium text-black-700 block"
            >
              Please record a 3 minute or less video talking about your business.
            </label>
              <div className="mt-1">
                <Link className="underline text-blue-900"
           to={selectedApp?.businessVideo}
           target="_blank">{selectedApp?.businessVideo}</Link>
            </div>
          </div>
        <div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
          <div className="w-full">
            <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-4"
            >
              Tell Us About Your Business Plan:
            </label>
            <div className="mt-1">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.businessPlan}
              />
            </div>
          </div>
           <div className="mt-2 w-full">
             <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-4"
            >
              How Are You Planning To Use Our Space:
            </label>
            <div className="mt-1">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.orgUses}
              />
            </div>
          </div>
        </div>
        <div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
          <div className="w-full ">
            <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-4"
            >
              What Is Your funding Strategy:
            </label>
            <div className="">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.fundingStrategy}
              />
            </div>
          </div>
  <div className="w-full ">
      <label
        htmlFor="orgMission"
        className="text-md font-medium text-black-700 block mb-4"
      >
        When Do You Project Your Breakeven Point:
      </label>
      <div className="">
        <textarea
          disabled
          className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
          defaultValue={selectedApp?.breakevenPoint}
        />
      </div>
    </div>
        </div>       
        <div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
          <div className="w-full ">
            <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-4"
            >
             How Many Employees Do You Have? How Many Do You Plan To Hire In The Next 12/24/36 Months?
            </label>
            <div className="">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.planHire}
              />
            </div>
          </div>
            <div className="w-full ">
            <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-10"
            >
              What Are Your Organization's Top Three Challenges:
            </label>
            <div className="">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.threeChallenges}
              />
            </div>
          </div>
        </div>
        <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
          <div className="w-full">
          </div>
        </div>
        <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
          <div className="w-full">
            <label
              htmlFor="facebook"
              className="text-md font-medium text-black-700 block mb-4"
            >
              # of Team Members Applying for Scholarship
            </label>
            <div className="mt-1">
              <input
                type="number"
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                placeholder={selectedApp?.orgAmount}
              />
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="linkedin"
              className="text-md font-medium text-black-700 block mb-4"
            >
              # of Team Members Awarded Scholarship
            </label>
              <div className="mt-1">
              <SelectInput
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                setSelectedValue={handleCharlotteAnnualAward}
                options={orgAmountOPTIONS}
                placeholder={selectedApp?.awardedMember ? selectedApp?.awardedMember : selectedApp?.scoreMemAwarded}
              />
            </div>
          </div>
        </div>
        <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
          <div className="w-full">
            <label
              htmlFor="awardAmount"
              className="text-md font-medium text-black-700 block mb-4"
            >
              Annual Award ($109 x member for 12 months)
            </label>
            <div className="mt-1">
              <input
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                type="awardAmount"
                name="awardAmount"
                id="awardAmount"
                value={amount}
                placeholder={selectedApp?.annualAward}
              />
            </div>
          </div>
          
        </div>   
        
      </>}
      </>
     )}
     {selectedApp?.type === "boost" && selectedApp?.market === "charlotte" && (
        <>
        {selectedApp?.orgMission ? (
          <div>
            <div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="mt-2 w-full">
												<label
													htmlFor="orgMission"
													className="block text-md font-medium text-gray-700 mb-4"
												>
													Description of Your Organization and Mission:
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={selectedApp?.orgMission}
													/>
												</div>
											</div>

											<div className="w-full">
												<label
													htmlFor="facebook"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
												Clear Mission (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														placeholder={selectedApp?.scoreMission}
														defaultValue={selectedApp?.scoreMission}
														setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="orgAlign"
													className="block text-md font-medium text-black-700"
												>
													How will using COhatch's locations and resources help you reach your goals?
												</label>
												<div className="mt-1">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={selectedApp?.orgUses}
													/>
												</div>
											</div>
											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-md font-medium text-gray-700 mb-4 mt-4"
												>
													COhatch Fit (1-3 Scale)
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={scorecardOPTIONS}
														value={selectedApp?.scoreFit}
														placeholder={selectedApp?.scoreFit}
														setSelectedValue={(evt) => handleChange("scoreFit", evt.value)}
													/>
												</div>
											</div>
										</div>

										<div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
											<div className="w-full ">
												<label
													htmlFor="orgMission"
													className="text-md font-medium text-black-700"
												>
													What social & economic impact does your company have on the local community?
												</label>
												<div className="">
													<textarea
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
														defaultValue={selectedApp?.orgAlign}
													/>
												</div>
											</div>

											<div className="w-full">
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-md font-medium text-gray-700 mb-4 mt-4"
													>
														Community Impact (1-3 Scale)
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={scorecardOPTIONS}
															placeholder={selectedApp?.scoreImpact}
															value={selectedApp?.scoreImpact}
															setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
														/>
													</div>
												</div>
											</div>
										</div>

										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
											</div>
										</div>
										<div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="facebook"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Applying for Scholarship
												</label>
												<div className="mt-1">
													<input
														type="number"
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														placeholder={selectedApp?.orgAmount}
													/>
												</div>
											</div>
											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-sm font-medium text-gray-700"
												>
													# of Team Members Awarded Scholarship (5 max)
												</label>
													<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														setSelectedValue={handleCharlotteAnnualAward}
														options={orgAmountOPTIONS}
														placeholder={selectedApp?.awardedMember ? selectedApp?.awardedMember : selectedApp?.scoreMemAwarded}
													/>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="score"
													className="block text-sm font-medium text-gray-700"
												>
													Total Score (9 maximum)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														name="score"
														id="score"
														placeholder={selectedApp?.scoreFit + selectedApp?.scoreMission + selectedApp?.scoreImpact}
													//  value={payload.scoreFit + payload.scoreMission + app.scoreImpact}
													/>
												</div>
											</div>

											<div className="w-full">

												<label
													htmlFor="awardAmount"
													className="block text-sm font-medium text-gray-700"
												>
													Annual Award ($109 x member for 12 months)
												</label>
												<div className="mt-1">
													<input
														disabled
														className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
														type="awardAmount"
														name="awardAmount"
														id="awardAmount"
														value={amount}
                            placeholder={selectedApp?.annualAward}
													/>
												</div>
											</div>

										</div>

          </div>
        ):  <>
         <div className="w-full mb-7">
            <label
              htmlFor="linkedin"
              className="text-md font-medium text-black-700 block"
            >
              Please record a 3 minute or less video talking about your business.
            </label>
              <div className="mt-1">
                <Link className="underline text-blue-900"
           to={selectedApp?.businessVideo}
           target="_blank">{selectedApp?.businessVideo}</Link>
            </div>
          </div>
        <div className="mt-4 mb-3 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
          <div className="w-full">
            <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-4"
            >
              Tell Us About Your Business Plan:
            </label>
            <div className="mt-1">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.businessPlan}
              />
            </div>
          </div>
           <div className="mt-2 w-full">
             <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-4"
            >
              How Are You Planning To Use Our Space:
            </label>
            <div className="mt-1">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.orgUses}
              />
            </div>
          </div>
        </div>
        <div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
          <div className="w-full ">
            <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-4"
            >
              What Is Your funding Strategy:
            </label>
            <div className="">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.fundingStrategy}
              />
            </div>
          </div>
  <div className="w-full ">
      <label
        htmlFor="orgMission"
        className="text-md font-medium text-black-700 block mb-4"
      >
        When Do You Project Your Breakeven Point:
      </label>
      <div className="">
        <textarea
          disabled
          className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
          defaultValue={selectedApp?.breakevenPoint}
        />
      </div>
    </div>
        </div>       
        <div className="mb-3 w-full  sm:flex sm:justify-between sm:gap-3 ">
          <div className="w-full ">
            <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-4"
            >
             How Many Employees Do You Have? How Many Do You Plan To Hire In The Next 12/24/36 Months?
            </label>
            <div className="">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.planHire}
              />
            </div>
          </div>
            <div className="w-full ">
            <label
              htmlFor="orgMission"
              className="text-md font-medium text-black-700 block mb-10"
            >
              What Are Your Organization's Top Three Challenges:
            </label>
            <div className="">
              <textarea
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 "
                defaultValue={selectedApp?.threeChallenges}
              />
            </div>
          </div>
        </div>
        <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
          <div className="w-full">
          </div>
        </div>
        <div className="mb-10 w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
          <div className="w-full">
            <label
              htmlFor="facebook"
              className="text-md font-medium text-black-700 block mb-4"
            >
              # of Team Members Applying for Scholarship
            </label>
            <div className="mt-1">
              <input
                type="number"
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                placeholder={selectedApp?.orgAmount}
              />
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="linkedin"
              className="text-md font-medium text-black-700 block mb-4"
            >
              # of Team Members Awarded Scholarship
            </label>
              <div className="mt-1">
              <SelectInput
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                setSelectedValue={handleCharlotteAnnualAward}
                options={orgAmountOPTIONS}
                placeholder={selectedApp?.awardedMember ? selectedApp?.awardedMember : selectedApp?.scoreMemAwarded}
              />
            </div>
          </div>
        </div>
        <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
          <div className="w-full">
            <label
              htmlFor="awardAmount"
              className="text-md font-medium text-black-700 block mb-4"
            >
              Annual Award ($109 x member for 12 months)
            </label>
            <div className="mt-1">
              <input
                disabled
                className="block w-full px-3 py-2 border text-gray-700 border-black rounded-md shadow-sm placeholder-gray-400 bg-white"
                type="awardAmount"
                name="awardAmount"
                id="awardAmount"
                value={amount}
                placeholder={selectedApp?.annualAward}
              />
            </div>
          </div>
        </div>   
      </>}
      </>
     )}

      </div>
      <div className="mt-10">
      <h1 className="mb-8 font-bold text-xl">
       Status
      </h1>
      <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
      {userState.role[0] === "COMMUNITY_MANAGER" || userState.role[0] === "MASTER_APPROVAL" ? (
        <div className="w-full mb-5">
        <label
          htmlFor="facebook"
          className="block  font-medium text-gray-700"
        >
          Community Manager Approval Status
        </label>
        <div className="bg-white mt-1">
          {selectedApp?.cmStatus === "pending" ? 
            <SelectInput
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md capitalize"
            placeholder={String(selectedApp?.cmStatus).toUpperCase()}
            options={statusOPTIONS}
            setSelectedValue={(evt) => handleChange("cmStatus", evt.value)}
          /> :
            <div className="mt-1">
          <div  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
          {String(selectedApp?.cmStatus).toUpperCase()}
              </div>
        </div>}
        
        </div>
      </div>
      ) :
      <div className="w-full">
        <label
          htmlFor="facebook"
          className="block font-medium text-gray-700"
        >
          Community Manager Approval Status
        </label>
        <div className="bg-white mt-1">
          
          <div  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
          {String(selectedApp?.cmStatus).toUpperCase()}
              </div>
        </div>
      </div>}

      {userState.role[0] === "MARKET_LEADER" || userState.role[1] === "MARKET_LEADER" || userState.role[0] === "MASTER_APPROVAL" ? (
        <div className="w-full">
          <label
          htmlFor="linkedin"
          className="block font-medium text-gray-700"
        >
          Market Leader Approval Status
        </label>
        <div className="mt-1 bg-white">
          {selectedApp?.mlStatus === "pending" && (selectedApp?.cmStatus != "pending")? 
          <SelectInput
            className="capitalize appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
            options={statusOPTIONS}
            setSelectedValue={(evt) => handleChange("mlStatus", evt.value)}
            placeholder={String(selectedApp?.mlStatus).toUpperCase()}
          /> : 
          <div className="mt-1">
          <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
          {String(selectedApp?.mlStatus).toUpperCase()}
              </div>
        </div>}
          
        </div>
        </div>
      ) : 
      <div className="w-full">
        <label
          htmlFor="mlStatus"
          className="block font-medium text-gray-700"
        >
          Market Leader Approval Status
        </label>
        <div className="mt-1">
          
          <div  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
          {String(selectedApp?.mlStatus).toUpperCase()}
              </div>
        </div>
      </div>}
      </div>
      <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
        {selectedApp?.type === "give" && (
          <div className="w-full">
          <label
            htmlFor="facebook"
            className="block font-medium text-gray-700"
          >
            501C3 Approved
          </label>
          <div className="mt-1">
            <SelectInput
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
              options={statusOPTIONS}
              placeholder={String(selectedApp?.status501).toUpperCase()}
              //defaultValue={app.admin.cmStatus}
              // placeholder="Select an option"
              //setSelectedValue={(event) => setCMStatus(event.value)}
              setSelectedValue={(evt) => { handleChange("status501", evt.value) }}
            />
          </div>
        </div>
        )}

        <div className="w-full">
          <label
            htmlFor="linkedin"
            className="block font-medium text-gray-700"
          >
            OfficeRnD Status
          </label>
          <div className="mt-1">
            <SelectInput
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
              options={statusOPTIONS}
              // placeholder="Select an option"
              setSelectedValue={(evt) => { handleChange("officeRnd", evt.value) }}
              //value={app.admin.mlStatus}
              placeholder={String(selectedApp?.officeRnd).toUpperCase()}
            />
          </div>
        </div>
      </div>
      </div>
      		<div className="mt-4 w-full flex justify-end">
            <div className="mt-3">
              <button
                className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                type="button"
                onClick={() => {
                  handleUpdate(payload);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
       </div>
       
       )} 

      {selectedTab === "Application" && (
        <div className="mt-10 col-span-4 ">
          {selectedApp && (
            <ApplicationFile
        applicationId={selectedApp._id} />
          )}
      
      </div>
    )}

    {/* Info Overview Section */}
    <div className="mt-2 col-span-2">
      <div className="rounded-md p-3 bg-white shadow-lg">
        {selectedApp?.type === "boost" && selectedApp?.jobRole != null ? (
             <div className="mb-3">
            <h1 className="font-bold ">
              Job Role
            </h1>
            <p className="text-gray-600">
              {selectedApp?.jobRole}
            </p>
          </div>
          ) : null}
          <div className="mb-3">
            <h1 className="font-bold">
              Location & Market
            </h1>
            <p className="capitalize text-gray-600">
              {selectedApp?.coLocation} | {selectedApp?.market}
            </p>
          </div>
           <div className="mb-3">
            <h1 className="font-bold ">
              Email
            </h1>
            <p className="truncate hover:text-clip text-gray-600">
              {selectedApp?.email}
            </p>
          </div>
           <div className="mb-3">
            <h1 className="font-bold ">
              Phone Number
            </h1>
            <p className="text-gray-600">
              {selectedApp?.phoneNumber}
            </p>
          </div>
          <div className="mb-3">
            <h1 className="font-bold "> 
              Website
            </h1>
            <p className="text-gray-600">
            {selectedApp?.website}
            </p>
          </div>
           {selectedApp?.type === "boost" && selectedApp?.dateFounded != null ? (
             <div className="mb-3">
            <h1 className="font-bold ">
              Date Founded
            </h1>
            <p className="text-gray-600">
              {selectedApp?.dateFounded}
            </p>
          </div>
          ) : null}
          <div className="mb-3">
            <h1 className="font-bold "> 
              Submission Date
            </h1>
            <p className="text-gray-600">
            <time >
          {format(new Date(createdAt), "MMMM do, yyyy")}
              </time>
            </p>
          </div>
           <div className="mb-3">
            <h1 className="font-bold "> 
              Renewal Date
            </h1>
            <p className="text-gray-600">
            	<time >{selectedApp?.reapplicant === "no" ?  `${format(new Date(newDate), "MMMM do, yyyy")}` : `${format(new Date("2025-01-01T20:35:47.158Z"), "MMMM do, yyyy")}`}
            </time>
            </p>
          </div>
          <div className="mb-3">
            <h1 className="font-bold "> 
              Sponsorship
            </h1>
            <p className="text-gray-600">
            {selectedApp?.orgSponsored === "no" ?  "Not Sponsored" : `Sponsored by: ${selectedApp?.orgSponsored}`}
            </p>
          </div>
           <div className="mb-3">
            <h1 className="font-bold ">
              Applicant Contacted ?
            </h1>
              <button
              className={`bg-${isContacted ? 'green' : 'blue'}-500 hover:bg-${isContacted ? 'green' : 'blue'}-700 text-white font-bold py-2 px-4 border border-2 rounded focus:outline-none focus:shadow-outline`}
              style={{ color: 'black' }} // Set contrasting text color
              onClick={handleClick}
            >
              {selectedApp?.applicantContacted ? selectedApp?.applicantContacted : "No"}
            </button>
          </div>
          <div className="mb-3">
            <button className="cursor-pointer relative rounded shadow-sm px-2.5 py-2 text-white  bg-success-800 "
          size="md" 
          onClick={() => setShowApplicantModal(true)}>Edit Application</button>
          {selectedApp && (
            <EditApplicationModal
            open={showApplicantModal}
            onClose={() => setShowApplicantModal(false)}
            app={selectedApp}
            handleSubmit={handleSubmit}
          /> 
          )}
          
          </div>        
          {isModalOpen && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center">
								 <div>
                  <p>Are you sure you want to update the status?</p>
                  <button  className="mb-2 ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500" onClick={handleConfirm}>Yes</button>
                  <ButtonPrimary  className="text-gray-700 block px-4 py-2 text-sm" text="Cancel" action={handleCancel}/>
                </div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
      </div>
    </div>
      
    </div>
</>
  );
}

export default ApplicantProfile;